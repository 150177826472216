export const LOGOUT_ACTION = "LOGOUT";
export const DEFAULT_ERROR =
  "Something seems to have gone wrong. Please try again";
export const Months = [
  {
    key: 1,
    value: "Jan",
  },
  {
    key: 2,
    value: "Feb",
  },
  {
    key: 3,
    value: "March",
  },
  {
    key: 4,
    value: "April",
  },
  {
    key: 5,
    value: "May",
  },
  {
    key: 6,
    value: "June",
  },
  {
    key: 7,
    value: "July",
  },
  {
    key: 8,
    value: "Aug",
  },
  {
    key: 9,
    value: "Sept",
  },
  {
    key: 10,
    value: "Oct",
  },
  {
    key: 11,
    value: "Nov",
  },
  {
    key: 12,
    value: "Dec",
  },
];

export const ADD_ACTION = "ADD_COUPON";
export const UPDATE_ACTION = "UPDATE_COUPON";
export const DELETE_ACTION = "DELETE_COUPON";
export const DELETEALL_ACTION = "DELETEALL_COUPON";

export const add = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_ACTION,
      data,
    });
  };
};

export const update = (data) => {
  console.log(data);
  console.log(data);

  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTION,
      data,
    });
  };
};

export const del = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_ACTION,
    });
  };
};

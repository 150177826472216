import { ADD_ACTION, UPDATE_ACTION, DELETE_ACTION } from "../actions/cart";

const cart = {};

export default (state = cart, action) => {
  switch (action.type) {
    case ADD_ACTION:
      return action.data;
    case UPDATE_ACTION:
      return action.data;
    case DELETE_ACTION:
      return action.data;
    default:
      return state;
  }
};

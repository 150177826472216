import { ADD_ACTION, DELETE_ACTION } from "../actions/paymentrequest";

const paymentRequest = {};

export default (state = paymentRequest, action) => {
  switch (action.type) {
    case ADD_ACTION:
      return action.data;
    case DELETE_ACTION:
      return {
        code: "",
        discount: "",
      };
    default:
      return state;
  }
};

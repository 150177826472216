import axios from "axios";

const instance = axios.create({
  baseURL: "https://legalapi.onrender.com",
  // baseURL: "http://localhost:3001/",
  //baseURL: "http://192.168.1.58:3001/",
  //baseURL: "http://1221442aaca2.ngrok.io/",
});

export default instance;

import React from "react";
import { Typography, makeStyles, Grid, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  popover_iconContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
  },
  popover_icon: {
    marginRight: "27%",
    [theme.breakpoints.down("md")]: {
      marginRight: "15%",
    },
    content: "",
    right: 100,
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "0 20px 40px 20px",
    borderColor: `transparent transparent ${theme.palette.primary.main}  transparent`,
    zIndex: 9998,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contentDiv: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    boxShadow: theme.boxShadow,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  paper: {
    padding: "50px 175px 50px 175px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 10px 10px 10px",
    },
  },
  thankU: {
    fontSize: 94,
    color: theme.palette.text.secondary,
    fontFamily: theme.fontfamily,
    marginBottom: "2%",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 60,
    },
  },
  inTouch: {
    fontSize: 20,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    fontFamily: theme.fontfamily,
    marginBottom: "6%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      maxWidth: "95%",
      fontSize: 14,
    },
  },
  getBack: {
    fontSize: 27,
    fontWeight: "bold",

    color: theme.palette.text.secondary,
    fontFamily: theme.fontfamily,
    marginBottom: "1%",
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95%",
      fontSize: 14,
    },
  },
  btn: {
    height: 40,
    width: 130,
    marginTop: "5%",
    font: `Bold 16px ${theme.fontfamily}`,
    borderRadius: 40,
    color: theme.palette.text.secondary,
    border: `solid 2px ${theme.palette.secondary.light}`,
    marginRight: 10,
  },
  btn1: {
    height: 40,
    width: 130,
    marginTop: "5%",
    font: `Bold 16px ${theme.fontfamily}`,
    borderRadius: 40,

    backgroundColor: theme.palette.secondary.light,
  },
  btn2: {
    height: 40,
    width: 280,
    marginTop: "5%",
    font: `Bold 16px ${theme.fontfamily}`,
    borderRadius: 40,

    backgroundColor: theme.palette.secondary.light,
  },
  btnLabel: {
    font: `Bold 16px ${theme.fontfamily}`,
  },
  wrapperImg: {
    marginTop: "1%",
    marginBottom: "5%",
  },
}));
const LoginCallout = (props) => {
  const classes = useStyles();

  const gotoLogin = () => {
    props.handleClose();
    props.history.push("/login");
  };
  const gotoSignup = () => {
    props.handleClose();
    props.history.push("/signup");
  };

  const gotoCorporate = () => {
    props.handleClose();
    props.history.push(`/occasions/1`);
  };
  return (
    <div style={{ backgroundColor: "transparent" }} className={classes.root}>
      <div className={classes.popover_iconContainer}>
        <div className={classes.popover_icon}></div>
      </div>

      <div className={classes.contentDiv}>
        <Grid className={classes.paper}>
          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapperImg}
          >
            <img alt="logo" src="/assets/Loginpopup.svg" />
          </Grid>
          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapper}
          >
            <Typography className={classes.thankU}>Welcome!</Typography>
          </Grid>

          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapper1}
          >
            <Typography className={classes.getBack}>
              {`Signup & get attractive offers,`}
            </Typography>
          </Grid>

          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapper1}
          >
            <Typography className={classes.getBack}>
              track offers, easy checkout and more.
            </Typography>
          </Grid>

          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapper}
          >
            <Button className={classes.btn} onClick={gotoSignup}>
              Signup
            </Button>
            <Button className={classes.btn1} onClick={gotoLogin}>
              <Typography color="primary" className={classes.btnLabel}>
                Login
              </Typography>
            </Button>
            <Grid
              xs={12}
              item
              container
              justify="center"
              className={classes.wrapper}
            >
              <Button className={classes.btn2} onClick={gotoCorporate}>
                <Typography color="primary" className={classes.btnLabel}>
                  Corporate Enquiry
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default LoginCallout;

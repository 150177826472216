import React from "react";
import { makeStyles, Typography, Grid, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 420,
    padding: "40px 40px 40px 40px",
    [theme.breakpoints.down("xs")]: {
      width: 250,
    },
  },
  labelSection: {
    borderBottom: `solid 2px ${theme.palette.primary.main}`,
  },
  headingTxt: {
    font: `Bold 20px/30px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
    textAlign: "left",
  },

  emptyImage: {
    padding: "60px 0px 17px 0px",
  },
  eImg: {
    [theme.breakpoints.down("xs")]: {
      width: 100,
    },
  },
  imageTxt: {
    font: `Bold 16px/20px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
  },
  btn: {
    height: 40,
    width: 170,
    marginTop: "5%",
    font: `Bold 16px ${theme.fontfamily}`,
    borderRadius: 40,
    color: theme.palette.text.secondary,
  },
}));

const EmptyCart = (props) => {
  const classes = useStyles();
  const handleClose = () => {
    if (props.closeCartRef.current) {
      props.closeCartRef.current.click();
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.labelSection}>
        <Typography className={classes.headingTxt}>Your Cart </Typography>
      </div>
      <Grid container justify="center" className={classes.emptyImage}>
        <img alt="empty" src="/assets/emptyCart.svg" className={classes.eImg} />
      </Grid>
      <Grid container justify="center">
        <span className={classes.imageTxt}>Your Shopping Cart is empty!</span>
      </Grid>
      <Grid container justify="center">
        <Button className={classes.btn} onClick={handleClose}>
          Start Shopping
        </Button>
      </Grid>
    </div>
  );
};

export default EmptyCart;

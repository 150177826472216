import React from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    width: "80%",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    padding: theme.spacing(2, 4, 3),
  },
  getBtn: {
    width: 300,
    height: 60,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.secondary,
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#2c3b6d",
    },
  },
}));
const Loader = (props) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;

import React, { useState } from "react";
import { makeStyles, Grid, TextField } from "@material-ui/core";
import SidePanelAuth from "../../components/sidepanelauth";
import BlueBackBtn from "../../components/bluebackbtn";
import axios from "../../axios";
import withErrorHandler from "../../containers/withErrorHandler";
import { NavLink } from "react-router-dom";

const useStyes = makeStyles((theme) => ({
  rightPanel: {
    padding: "50px 10px 0px 20px",
  },
  formGrid: {
    display: "flex",
    //alignItems: "center",
    justifyContent: "center",
    height: "80%",
    paddingLeft: "30%",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      padding: "60px 10px 10px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  containerDiv: {
    marginBottom: 15,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0,
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
  },
  input: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  horrizontalLine: {
    width: 350,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    lineHeight: "0.1em",
    font: `Bold 16px ${theme.fontfamily}`,
    marginTop: 30,
    marginBottom: 30,
  },
  okTxt: {
    background: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    padding: "0px 6px 0px 6px",
  },
  input1: {
    width: 208,
    marginRight: 14,
    WebkitAppearance: "none",
    MozAppearance: "textfield",
  },
  input2: {
    width: 128,
  },
  pwdMatchTxt: {
    font: `Bold 12px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
  },
  swicthAuthSection: {
    textAlign: "right",
    width: "100%",
  },
  noAccout: {
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
    marginRight: 15,
  },
  switchLink: {
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
  },
  header: {
    font: `Bold 30px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
    marginBottom: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
}));

const ForgotPassword = (props) => {
  const [email, setEmail] = useState();
  const [password1, setpwd1] = useState();
  const [password2, setpwd2] = useState();
  const [otp, setOtp] = useState();
  const [otpsent, setOtpSent] = useState(false);
  const [otpverified, setOtpVerified] = useState(false);
  const classes = useStyes();

  const submitForm = async (e) => {
    e.preventDefault();
    let url;
    let cb;
    let request = {};
    if (!otpsent) {
      url = "sendresetotp";
      cb = () => {
        setOtpSent(true);
      };
      request.email = email;
      request.changepwd = true;
    } else if (otpsent && !otpverified) {
      url = "verifyresetotp";
      cb = () => {
        setOtpVerified(true);
      };
      request.otp = otp;
      request.email = email;
    } else if (otpsent && otpverified) {
      // eslint-disable-next-line
      if (password1 != password2) {
        return;
      }
      url = "setpassword";
      cb = () => {
        props.history.goBack();
      };
      request.password = password2;
      //await axios.post(url, request);
    } else {
      return;
    }
    request.email = email;
    request.password = password1;
    try {
      await axios.post(url, request);
      if (cb) {
        cb();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid container className={classes.root}>
      <SidePanelAuth />
      <Grid xs={12} md={7} item className={classes.rightPanel}>
        <div className={classes.swicthAuthSection}>
          <span className={classes.noAccout}>Don’t have an account?</span>
          <NavLink className={classes.switchLink} to="/signup">
            Signup Now
          </NavLink>
        </div>
        <Grid container className={classes.formGrid}>
          <div className={classes.header}>Forgot password</div>
          <form onSubmit={(e) => submitForm(e)}>
            <div className={classes.containerDiv}>
              <TextField
                variant="outlined"
                size="small"
                type="text"
                placeholder="Email Address or Mobile "
                className={classes.input}
                required
                disabled={otpsent}
                onChange={(e) => {
                  const val = e.target.value;
                  setEmail(val);
                  setOtpSent(false);
                  setOtpVerified(false);
                }}
                InputProps={{
                  style: {
                    color: "#615A5A",
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                  },
                }}
              />
            </div>

            {otpsent && !otpverified && (
              <>
                <div className={classes.containerDiv}>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    placeholder="Enter OTP"
                    className={classes.input2}
                    required={otpsent && !otpverified}
                    InputProps={{
                      style: {
                        color: "#615A5A",
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                      },
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setOtp(value);
                    }}
                  />
                </div>
              </>
            )}
            {otpverified && (
              <>
                <div className={classes.containerDiv}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Enter new password "
                    className={classes.input}
                    required={otpverified}
                    onChange={(e) => {
                      const val = e.target.value;
                      setpwd1(val);
                    }}
                    type="text"
                    InputProps={{
                      style: {
                        color: "#615A5A",
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                      },
                    }}
                  />
                </div>

                <div className={classes.containerDiv}>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="password"
                    placeholder="Confirm Password "
                    className={classes.input}
                    required={otpverified}
                    onChange={(e) => {
                      const val = e.target.value;
                      setpwd2(val);
                    }}
                    InputProps={{
                      style: {
                        color: "#615A5A",
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                      },
                    }}
                  />
                  {
                    // eslint-disable-next-line
                    password1 != password2 && (
                      <div className={classes.pwdMatchTxt}>
                        Passwords do not match
                      </div>
                    )
                  }
                </div>
              </>
            )}
            <div className={classes.containerDiv} style={{ display: "flex" }}>
              <BlueBackBtn
                width={130}
                radius={33}
                label={
                  otpsent ? (otpverified ? "Confirm" : "Verify") : "Send OTP"
                }
                right={85}
              />
            </div>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withErrorHandler(ForgotPassword, axios);

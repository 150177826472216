import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const AlertMessage = (props) => {
  return (
    <Snackbar
      open={props.error ? true : false}
      autoHideDuration={6000}
      onClose={props.errorConfirmedHandler.bind(this, "")}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={props.errorConfirmedHandler.bind(this, "")}
        severity={props.severity || "error"}
      >
        {props.error}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;

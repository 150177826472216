import React, { useState } from "react";
import { IconButton, makeStyles, TextField } from "@material-ui/core";
import BlueBackBtn from "./bluebackbtn";
import { useDispatch, useSelector } from "react-redux";
import * as CouponActions from "../store/actions/coupon";
import axios from "../axios";
import CloseIcon from "@material-ui/icons/Close";

const _ = require("lodash");
const useStyles = makeStyles((theme) => ({
  discountTxt: {
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  applyCouponSection: {
    display: "inline-flex",
    paddingTop: 10,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0,
      },
    },
    "& .MuiSelect-select.MuiOutlinedInput-input": {
      paddingTop: 7,
      paddingBottom: 7,
      borderRadius: 33,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
    },
  },
  txtField: {
    "& .MuiInputBase-root": {
      font: "Bold 16px/20px Quicksand",
      backgroundColor: "#ffff",
      color: "#615A5A",
      border: "1px solid #C9C9C9",
      width: 275,
      [theme.breakpoints.down("xs")]: {
        width: 180,
        fontSize: 12,
      },
    },
  },
  errorTxt: {
    font: `Bold 12px ${theme.fontfamily}`,
    color: "red",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
}));

const ApplyCoupon = (props) => {
  const coupon = useSelector((state) => state.coupon);
  const [code, setCode] = useState(coupon.code);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const classes = useStyles();

  const applyCoupon = async () => {
    setError("");
    if (!code) {
      setError("Invalid code");
      return;
    }

    let remCouponAction = CouponActions.del();
    dispatch(remCouponAction);

    try {
      const response = await axios.post(`applycoupon/${code}`, {
        paymentSummary: props.paymentSummary,
      });

      const discount = _.get(response, ["data", "message"]);

      let action = CouponActions.add({
        discount,
        code,
      });
      dispatch(action);
    } catch (err) {
      setError(
        _.get(err, ["response", "data", "message"]) || "Problem occured"
      );
    }
  };

  const delCoupon = () => {
    let remCouponAction = CouponActions.del();
    dispatch(remCouponAction);
  };
  return (
    <>
      <div>
        <span className={classes.discountTxt}>
          Have a discount or offer code?
        </span>
      </div>
      <div className={classes.applyCouponSection}>
        <TextField
          className={classes.txtField}
          variant="outlined"
          size="small"
          placeholder="Enter coupon code here . . . "
          defaultValue={coupon.code}
          value={code}
          onChange={(e) => {
            const val = e.target.value;
            setCode(val);
          }}
        />

        <BlueBackBtn
          label="APPLY"
          left={10}
          width={100}
          radius={5}
          action={applyCoupon}
        />
      </div>

      {coupon.discount && (
        <div>
          <span
            className={classes.discountTxt}
            style={{ color: "green" }}
          >{`Congrats ₹${coupon.discount} waived`}</span>
          <IconButton onClick={delCoupon}>
            <CloseIcon />
          </IconButton>
        </div>
      )}

      {error && (
        <div>
          <span className={classes.errorTxt}>{error}</span>
        </div>
      )}
    </>
  );
};

export default ApplyCoupon;

import React from "react";
import { makeStyles, Grid, Hidden } from "@material-ui/core";

const useStyes = makeStyles((theme) => ({
  leftPanel: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    minHeight: "100Vh",
    padding: "100px 0px 0px 70px",
  },
  footerLogo: {
    padding: "0px 0px 15px 0px",
  },
  imgText: {
    font: `Bold 27px ${theme.fontfamily}`,
    color: theme.palette.text.secondary,
    width: "100%",
  },
  bottomTxt: {
    position: "absolute",
    bottom: 75,
    left: "25%",
    font: `normal 31px KGHAPPYSolid`,
    color: theme.palette.text.secondary,
    width: 200,
    transform: "rotate(-15deg)",
  },
  bottomImg1: {
    position: "absolute",
    left: "10%",
    bottom: 0,
    transform: "scaleX(-1)",
  },
  bottomImg2: {
    position: "absolute",
    left: "45%",
    bottom: 0,
    transform: "scaleX(-1)",
  },
}));

const SidePanelAuth = (props) => {
  const classes = useStyes();

  return (
    <Hidden smDown>
      <Grid xs={4} item className={classes.leftPanel}>
        <img
          className={classes.footerLogo}
          src="/assets/footerLogo.svg"
          alt="logo"
        />
        <div className={classes.imgText}>Get attractive offers,</div>
        <div className={classes.imgText}>track offers, easy checkout</div>
        <div className={classes.imgText}>and more.</div>
        <div className={classes.bottomTxt}>Vocal For Local</div>
        <img className={classes.bottomImg1} alt="leaf1" src="/leaf2.png" />
        <img className={classes.bottomImg2} alt="leaf1" src="/leaf1.png" />
      </Grid>
    </Hidden>
  );
};

export default SidePanelAuth;

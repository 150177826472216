import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { useWindowSize } from "react-use";

const useStyles = makeStyles((theme) => ({
  freeDeliveryContainer: {
    font: "Bold 16px/20px Quicksand",
    color: "#615A5A",
  },
}));

const FreeDelivery = (props) => {
  const classes = useStyles();
  const currency = "₹";
  const { width } = useWindowSize();
  return (
    <Grid
      className={classes.freeDeliveryContainer}
      container
      justify={width > 800 ? "center" : "flex-start"}
      alignItems="center"
    >
      <Grid style={{ width: 216 }} item>
        {999 - props.total > 0 && (
          <span>
            Add products worth of {currency}
            {999 - props.total} more to get free delivery.
          </span>
        )}
      </Grid>
      <Grid item>
        <img src="/assets/free.svg" alt="gift" />
      </Grid>
    </Grid>
  );
};

export default FreeDelivery;

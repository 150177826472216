import React, {
  useState,
  useRef,
  useEffect,
  useReducer,
  useCallback,
  createRef,
} from "react";
import {
  makeStyles,
  Grid,
  Checkbox,
  TextField,
  MenuItem,
  Select,
  Paper,
  Button,
  Divider,
  Tooltip,
  CardActionArea,
} from "@material-ui/core";
import PaymentSummary from "../../components/paymentSummary";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { NavLink, Redirect } from "react-router-dom";
import BlueBackBtn from "../../components/bluebackbtn";
import AddressForm from "../../components/addressform";
import validator from "validator";
import withErrorHandler from "../../containers/withErrorHandler";
import * as LoginActions from "../../store/actions/auth";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import Loader from "../../containers/Loader";
import { DatePicker } from "@material-ui/pickers";
import AlertMessage from "../../containers/Error";
import * as CartActions from "../../store/actions/cart";
import HelpIcon from "@material-ui/icons/Help";
import { Months } from "../../GlobalConstants";
import clsx from "clsx";
import { withSnackbar } from "notistack";
import "./index.css";
import * as cardValidator from "card-validator";
import * as PaymentRequestActions from "../../store/actions/paymentrequest";

const _ = require("lodash");

/* function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
} */
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 90,
    [theme.breakpoints.down("xs")]: {
      marginTop: 30,
    },
  },
  leftSection: {
    padding: "50px 50px 10px 6.5%",

    flexDirection: "column",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0,
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiSelect-select.MuiOutlinedInput-input": {
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.palette.text.primary,
      font: "Bold 16px/20px Quicksand",
    },
    "& .MuiSelect-icon": {
      right: 0,
      top: 0,
    },
  },
  headerTxt: {
    font: `Bold 30px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
    marginBottom: 15,
  },

  checkBox: {
    fontSize: 30,
    color: theme.palette.primary.main,
  },
  methodmainWrapper: {
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
    },
  },
  methodmain: {
    font: "Bold 24px Quicksand",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },

  menuItem: {
    font: "Bold 16px/20px Quicksand",
    color: theme.palette.text.primary,
  },
  input2: {
    width: 318,
    [theme.breakpoints.down("xs")]: {
      width: 220,
    },
  },
  input: {
    "&::placeholder": {
      color: theme.palette.text.primary,
      fontWeight: "Bold",
    },
  },

  rightSection: {
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px 6.5%",
    },
  },
  subSectionSub: { marginTop: 20 },
  subSectionHeading: {
    minWidth: "100%",
    font: "Bold 16px/20px Quicksand",
    color: theme.palette.text.primary,
  },
  cvvHeading: {
    font: "Bold 16px/20px Quicksand",
    color: theme.palette.text.primary,
    marginRight: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      marginRight: 0,
    },
  },
  subSection: {
    padding: 30,

    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  cardNo: {
    width: 350,
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 10,
    },
  },
  cvv: {
    width: 120,
  },
  upi: { width: 250 },
  expiry: {
    width: 120,
    marginRight: 15,
    height: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  netBankingDropdown: {
    width: 350,
    height: 40,
  },
  wallet: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "black",
    height: 100,
    width: 100,
    display: "flex",
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    overflow: "hidden",
    padding: "5px 0px 5px 0px",
    [theme.breakpoints.down("md")]: {
      width: 40,
      height: 40,
    },
  },
  selectedWallet: {
    borderColor: theme.palette.primary.main,
    borderWidth: 5,
  },
  paybtn: {
    marginTop: 30,
    width: 300,
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      width: "97%",
    },
  },
  bankimg: {
    maxHeight: 90,
    maxWidth: 90,
    [theme.breakpoints.down("md")]: {
      width: 40,
    },
  },
}));

const addonYears = 40;
const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";
const FORM_INPUT_CORPORATE = "CORPORATE_TOGGLE";
const banks = ["HDFC", "SBIN", "ICIC", "PUNB_R"];

let razorpay;
const Payment = (props) => {
  const [fetching, setFetching] = useState(false);
  const token = localStorage.getItem("token");
  const [error, setError] = useState();
  // eslint-disable-next-line

  const payref = useRef();
  // eslint-disable-next-line
  const auth = useSelector((state) => state.auth);
  const request = useSelector((state) => state.paymentrequest);
  const [method, setMethod] = useState();
  const [supportedMethods, setSupportedMethods] = useState();
  const [selectedWallet, setSelectedWallet] = useState();
  const [upiId, setUpiId] = useState("");
  const [bank, setBank] = useState(0);
  const [cardno, setCardNo] = useState();
  const [cardName, setCardname] = useState();
  const [cvv, setcvv] = useState();
  const [carddMM, setCardMM] = useState(0);
  const [cardYY, setCardYY] = useState(0);
  const [cardtype, setCardtype] = useState();
  const classes = useStyles();
  const [orderData, setOrderData] = useState();
  const [paymentSummary, setPaymentSummary] = useState({
    totalItemAmount: 0,
    totalDiscount: 0,
    totalDiscountedAmount: 0,
    totalTax: 0,
    packingCharge: 0,
    shippingCharge: 0,
    totalAmount: 0,
    maxPreptimeHours: -1,
  });
  const dispatch = useDispatch();

  const date = moment();

  useEffect(() => {
    /*  const res = loadScript("https://checkout.razorpay.com/v1/checkout.js")
      .then(() => { */
    razorpay = new window.Razorpay({
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
    });

    razorpay.once("ready", function (response) {
      setSupportedMethods(response.methods);
    });
    /* })
      .catch(() => setError("Razorpay SDK failed to load. Are you online?")); */
  }, [setSupportedMethods]);

  const payWithPaytm = () => {
    axios
      .post("paytm", { ...request, paymentSummary })
      .then(({ data }) => {
        if (data.success) {
          setFetching(true);
          var config = {
            root: "",
            flow: "DEFAULT",
            data: {
              orderId: data.tokenData.orderId,
              token: data.tokenData.txnToken,
              tokenType: "TXN_TOKEN",
              amount: data.tokenData.txnAmount?.value,
            },
            merchant: {
              name: "Milkytray",
              redirect: false,
            },
            handler: {
              notifyMerchant: function (eventName, data) {
                /* console.log("notifyMerchant handler function called");
                  console.log("eventName => ", eventName);
                  console.log("data => ", data); */
              },
              transactionStatus: async function (data) {
                /* console.log("payment status ", data); */
                await axios.post("/paytmWebhook", data);
                if (data.STATUS === "TXN_SUCCESS") {
                  let action = CartActions.add({});
                  dispatch(action);
                  props.history.replace({
                    pathname: "/thankyou",
                    state: {
                      order_id: data["ORDERID"],
                    },
                  });
                  alert("Payment Successful");
                } else if (data.STATUS === "TXN_FAILURE") {
                  alert("Payment Failed");
                } else if (data.STATUS === "TXN_PENDING") {
                  alert("Payment Pending");
                }
              },
            },
          };

          // initialze configuration using init method
          window.Paytm.CheckoutJS.init(config)
            .then(function onSuccess() {
              // after successfully updating configuration, invoke Blink Checkout
              window.Paytm.CheckoutJS.invoke();
            })
            .catch(function onError(error) {
              console.log("error => ", error);
            })
            .finally(() => {
              setFetching(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const paywithRazorpay = async () => {
    let headers = {};
    if (token) {
      headers.Authorization = token;
    }

    try {
      let data = orderData;

      const options = {
        currency: _.get(props, ["history", "location", "state", "currency"]),
        amount: _.get(props, ["history", "location", "state", "amount"]),
        order_id: _.get(props, ["history", "location", "state", "id"]),
        email: _.get(props, ["history", "location", "state", "email"]),
        contact: _.get(props, ["history", "location", "state", "mobile"]),
        method: method,

        handler: function (response) {
          let action = CartActions.add({});
          dispatch(action);
          props.history.replace({
            pathname: "/thankyou",
            state: {
              order_id: data.id,
            },
          });
        },
      };

      if (method === "card") {
        options.card = {
          number: cardno,
          name: cardName,
          expiry_month: carddMM,
          expiry_year: cardYY,
          cvv: cvv,
        };
      } else if (method === "netbanking") {
        options.bank = bank;
      } else if (method === "wallet") {
        options.wallet = selectedWallet;
      } else if (method === "upi") {
        options.vpa = upiId;
      }

      // const popup = razorpay.createPayment(options);
      if (!payref.current) {
        return;
      }

      console.log("paying now");
      const btnFunc = () => {
        razorpay.createPayment(options);
        razorpay.on("payment.success", (resp) => {
          console.log("paidd ");

          let action = CartActions.add({});
          dispatch(action);
          let action1 = PaymentRequestActions.add({});
          dispatch(action1);
          console.log("paidd going");
          props.history.replace({
            pathname: "/thankyou",
            state: {
              order_id: _.get(props, ["history", "location", "state", "id"]),
            },
          });
        });
        razorpay.on("payment.error", (resp) => {
          props.enqueueSnackbar(resp.error.description, {
            variant: "error",
          });
        });
      };
      var btn = payref.current;
      btn.addEventListener("click", btnFunc);
      btn.click();
      btn.removeEventListener("click", btnFunc);

      // var btn = payref.current;
      // btn.addEventListener("click", function () {
      //   // has to be placed within user initiated context, such as click, in order for popup to open.
      //   razorpay.createPayment(options);

      //   razorpay.on("payment.success", (resp) => {
      //     let action = CartActions.add({});
      //     dispatch(action);
      //     let action1 = PaymentRequestActions.add({});
      //     dispatch(action1);
      //     props.history.replace({
      //       pathname: "/thankyou",
      //       state: {
      //         order_id: data.id,
      //       },
      //     });
      //   });
      //   razorpay.on("payment.error", (resp) => {
      //     props.enqueueSnackbar(resp.error.description, {
      //       variant: "error",
      //     });
      //   });
      // });
      // btn.click();
    } catch (error) {
      console.log(error);
    }
  };

  const onCardNoChange = (val) => {
    setCardNo(val);
    const resp = cardValidator.number(val);
    setCardtype(resp);
  };

  const getYears = () => {
    let respArr = [];

    const year = date.format("YY");
    for (let index = 0; index <= 40; index++) {
      respArr.push(
        <MenuItem key={index} value={Number(year) + index}>
          {Number(year) + index}
        </MenuItem>
      );
    }

    return respArr;
  };

  const payHandler = (props) => {
    if (!validations()) {
      return;
    }

    if (method === "paytm") {
      payWithPaytm();
    } else {
      paywithRazorpay();
    }
  };

  const validations = () => {
    let result = true;
    if (method === "card") {
      if (!cardno) {
        props.enqueueSnackbar("Invalid card number", {
          variant: "error",
        });
        result = false;
      } else {
        const cardLength = cardno.length;
        if (!Array.isArray(_.get(cardtype, ["card", "lengths"]))) {
          props.enqueueSnackbar("Invalid card number", {
            variant: "error",
          });
        } else {
          if (!_.get(cardtype, ["card", "lengths"]).includes(cardLength)) {
            props.enqueueSnackbar("Invalid card number", {
              variant: "error",
            });
          }
        }
      }

      if (!cvv || !validator.isNumeric(cvv)) {
        props.enqueueSnackbar("Invalid cvv", {
          variant: "error",
        });
        result = false;
      } else {
        if (!_.get(cardtype, ["card", "code", "size"])) {
          props.enqueueSnackbar("Invalid card number", {
            variant: "error",
          });
        } else {
          if (cvv.length !== _.get(cardtype, ["card", "code", "size"])) {
            props.enqueueSnackbar("Invalid cvv", {
              variant: "error",
            });
          }
        }
      }
      if (!cardName) {
        props.enqueueSnackbar("Invalid card name", {
          variant: "error",
        });
      }

      const expiryValid = cardValidator.expirationDate(`${carddMM}/${cardYY}`);

      if (!expiryValid.isValid) {
        props.enqueueSnackbar("Invalid expiry date", {
          variant: "error",
        });
      }
    } else if (method === "netbanking") {
      if (!bank || bank === 0) {
        props.enqueueSnackbar("Please choose a bank", {
          variant: "error",
        });
        result = false;
      }
    } else if (method === "wallet") {
      if (!selectedWallet) {
        props.enqueueSnackbar("Please choose a wallet", {
          variant: "error",
        });
        result = false;
      }
    } else if (method === "upi") {
      //const xresult = /^\w+@\w+$/.test(upiId);
      const xresult = /^\b[a-zA-Z0-9.]+@\w+$/.test(upiId);
      if (!xresult) {
        props.enqueueSnackbar("Invalid upi id", {
          variant: "error",
        });
        result = false;
      }
    } else if (method === "paytm") {
    } else {
      props.enqueueSnackbar("Choose a payment method", {
        variant: "error",
      });
      result = false;
    }
    return result;
  };

  /* if (_.isEmpty(request)) {
    return <Redirect to="/" />;
  } */
  return (
    <Grid container className={classes.root}>
      <button hidden className="btn" ref={payref}>
        This paragraph should be hidden.
      </button>
      <AlertMessage
        errorConfirmedHandler={setError.bind(this, "")}
        error={error}
        severity="error"
      />
      <Grid xs={12} sm={7} item container className={classes.leftSection}>
        <div className={classes.headerTxt}>Payment methods</div>
        <Divider />

        {_.get(supportedMethods, ["card"]) && (
          <div className={classes.methodmainWrapper}>
            <Checkbox
              size="small"
              checkedIcon={<CheckBoxIcon className={classes.checkBox} />}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkBox} />}
              checked={method === "card"}
              onChange={(e) => {
                const checked = e.target.checked;
                checked && setMethod("card");
              }}
              name="card"
            />
            <span className={classes.methodmain}>Credit/Debit Card</span>
            {method === "card" && (
              <div className={classes.subSection}>
                <div className={classes.subSectionSub}>
                  <div className={classes.subSectionHeading}>
                    Enter your card number (Visa, Mastercard, Amex)
                  </div>
                  <TextField
                    type="number"
                    className={classes.cardNo}
                    variant="outlined"
                    placeholder="card number"
                    value={cardno}
                    onChange={(e) => {
                      const val = e.target.value;
                      // console.log(val);
                      onCardNoChange(val);
                    }}
                    inputProps={{
                      minLength: 10,
                    }}
                  />
                </div>
                <div className={classes.subSectionSub}>
                  <div className={classes.subSectionHeading}>
                    Enter name on card
                  </div>
                  <TextField
                    type="text"
                    className={classes.cardNo}
                    variant="outlined"
                    placeholder="Name"
                    value={cardName}
                    onChange={(e) => {
                      const val = e.target.value;
                      setCardname(val);
                    }}
                  />
                </div>
                <div
                  className={classes.subSectionSub}
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className={classes.cvvHeading}>CVV </span>
                  <TextField
                    type="password"
                    className={classes.cvv}
                    variant="outlined"
                    placeholder="CVV"
                    value={cvv}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (validator.isNumeric(val)) setcvv(val);
                    }}
                    inputProps={{
                      maxLength: 3,
                      minLength: 5,
                    }}
                  />
                  <Tooltip
                    placement="right"
                    title={
                      <React.Fragment>
                        <img src="/assets/cvv.png" />
                      </React.Fragment>
                    }
                  >
                    <HelpIcon />
                  </Tooltip>
                </div>
                <div
                  className={classes.subSectionSub}
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className={classes.cvvHeading}>Expires On </span>
                  <Select
                    variant="outlined"
                    value={carddMM}
                    className={classes.expiry}
                    onChange={(e) => {
                      const val = e.target.value;
                      setCardMM(val);
                    }}
                  >
                    <MenuItem value={0}>Month</MenuItem>
                    {Months.map((item, idx) => {
                      return (
                        <MenuItem key={idx} value={item.key}>
                          {item.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Select
                    variant="outlined"
                    value={cardYY}
                    className={classes.expiry}
                    onChange={(e) => {
                      const val = e.target.value;
                      setCardYY(val);
                    }}
                  >
                    <MenuItem value={0}>YY</MenuItem>
                    {getYears()}
                  </Select>
                </div>
              </div>
            )}
          </div>
        )}
        {_.get(supportedMethods, ["netbanking"]) && (
          <div className={classes.methodmainWrapper}>
            <Checkbox
              size="small"
              checkedIcon={<CheckBoxIcon className={classes.checkBox} />}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkBox} />}
              checked={method === "netbanking"}
              onChange={(e) => {
                const checked = e.target.checked;
                checked && setMethod("netbanking");
              }}
              name="netbanking"
            />
            <span className={classes.methodmain}>Net Banking</span>
            {method === "netbanking" && (
              <>
                <div className={classes.subSection} style={{ display: "flex" }}>
                  {banks.map((item) => {
                    return (
                      <CardActionArea
                        onClick={() => setBank(item)}
                        className={clsx(classes.wallet, {
                          [classes.selectedWallet]: item === bank,
                        })}
                        key={item}
                      >
                        <img
                          src={`/assets/${item}.jpg`}
                          className={classes.bankimg}
                        />
                      </CardActionArea>
                    );
                  })}
                </div>
                <div className={classes.subSection}>
                  <Select
                    variant="outlined"
                    value={bank}
                    className={classes.netBankingDropdown}
                    onChange={(e) => {
                      const val = e.target.value;
                      setBank(val);
                    }}
                  >
                    <MenuItem value={0}>Bank</MenuItem>
                    {Object.keys(_.get(supportedMethods, ["netbanking"])).map(
                      (item, idx) => {
                        return (
                          <MenuItem value={item}>
                            {_.get(supportedMethods, ["netbanking", item])}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </div>
              </>
            )}
          </div>
        )}
        {_.get(supportedMethods, ["wallet"]) && (
          <div className={classes.methodmainWrapper}>
            <Checkbox
              size="small"
              checkedIcon={<CheckBoxIcon className={classes.checkBox} />}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkBox} />}
              checked={method === "wallet"}
              onChange={(e) => {
                const checked = e.target.checked;
                checked && setMethod("wallet");
              }}
              name="wallet"
            />
            <span className={classes.methodmain}>Other Wallets</span>
            {method === "wallet" && (
              <div className={classes.subSection} style={{ display: "flex" }}>
                {Object.keys(_.get(supportedMethods, ["wallet"])).map(
                  (item, idx) => {
                    if (!_.get(supportedMethods, ["wallet", item])) {
                      return;
                    }
                    return (
                      <CardActionArea
                        onClick={() => setSelectedWallet(item)}
                        className={clsx(classes.wallet, {
                          [classes.selectedWallet]: item === selectedWallet,
                        })}
                        key={idx}
                      >
                        <img
                          src={`/assets/${item}.jpg`}
                          className={classes.bankimg}
                        />
                      </CardActionArea>
                    );
                  }
                )}
              </div>
            )}
          </div>
        )}
        {_.get(supportedMethods, ["upi"]) && (
          <div className={classes.methodmainWrapper}>
            <Checkbox
              size="small"
              checkedIcon={<CheckBoxIcon className={classes.checkBox} />}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkBox} />}
              name="upi"
              checked={method === "upi"}
              onChange={(e) => {
                const checked = e.target.checked;
                checked && setMethod("upi");
              }}
            />
            <span className={classes.methodmain}>UPI</span>
            {method === "upi" && (
              <div className={classes.subSection}>
                <div
                  className={classes.subSectionSub}
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className={classes.cvvHeading}>UPI ID</span>
                  <TextField
                    type="text"
                    className={classes.upi}
                    variant="outlined"
                    value={upiId}
                    placeholder="example@bank"
                    onChange={(e) => {
                      const val = e.target.value;
                      setUpiId(val);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {/* <div className={classes.methodmainWrapper}>
          <Checkbox
            size="small"
            checkedIcon={<CheckBoxIcon className={classes.checkBox} />}
            icon={<CheckBoxOutlineBlankIcon className={classes.checkBox} />}
            name="acce"
            checked={method === "paytm"}
            onChange={(e) => {
              const checked = e.target.checked;
              checked && setMethod("paytm");
            }}
          />
          <span className={classes.methodmain}>Paytm</span>
        </div> */}
      </Grid>
      <Grid xs={12} sm={5} item container className={classes.rightSection}>
        <PaymentSummary setPaymentSummary={setPaymentSummary} />
        <Grid xs={12} item>
          <Button className={classes.paybtn} onClick={payHandler}>
            Pay and place order
          </Button>
        </Grid>
      </Grid>
      <Loader open={fetching} />
    </Grid>
  );
};

export default withSnackbar(Payment);

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import LoaderError, { ErrorBoundary } from "./components/loaderError";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { rootReducer as reducers } from "./rootReducer";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReduxThunk from "redux-thunk";
import {
  ThemeProvider,
  createTheme as createMuiTheme,
} from "@material-ui/core";
import { SnackbarProvider } from "notistack";

const store = reducers && createStore(reducers, applyMiddleware(ReduxThunk));
const QUICKSAND_FONT = "Quicksand";
const theme = createMuiTheme({
  fontfamily: "Quicksand",
  boxShadow:
    "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
  palette: {
    primary: {
      light: "#7BFAF5",
      main: "#6AB6B3",
      dark: "#615A5A",
      error: "#f44336",
      success: "#4caf50",
    },
    secondary: {
      light: "#ffffff",
      main: "#FF8080",
      dark: "#E5BC32",
      error: "#f44336",
      success: "#4caf50",
    },
    text: {
      primary: "#615A5A",
      secondary: "#ffffff",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      divider: "rgba(0, 0, 0, 0.12)",
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      root: {
        color: "#707070",
        fontfamily: QUICKSAND_FONT,
      },
    },
    MuiListItemText: { root: { flex: "0 1 auto" } },
    MuiFormControlLabel: {
      root: {
        fontFamily: QUICKSAND_FONT,
        color: "#707070",
      },
    },
    MuiMenuItem: {
      root: {
        color: "#707070",
        backgroundColor: "#ffffff",
        fontFamily: QUICKSAND_FONT,
        "&:hover": {
          backgroundColor: "#6AB6B3",
          color: "#ffffff",
        },
        "&$selected": {
          color: "#ffffff",
          backgroundColor: "#6AB6B3",
          "&:hover": {
            backgroundColor: "#6AB6B3",
            color: "#ffffff",
          },
        },
      },
    },

    MuiTableCell: {
      root: {
        padding: 5,
        fontFamily: QUICKSAND_FONT,
        fontSize: 20,
      },
      head: {
        padding: 16,
        fontFamily: QUICKSAND_FONT,
      },
    },

    MuiFormHelperText: {
      root: {
        color: "red",
      },
    },
    MuiButton: {
      root: {
        backgroundColor: "#6AB6B3",
        color: "#ffffff",
        textAlign: "center",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#7BFAF5",
          "@media (hover: none)": {
            backgroundColor: "#7BFAF5",
          },
        },
      },
      label: {
        color: "#ffffff",
        //font: `Bold 12px/15px ${QUICKSAND_FONT}`,

        fontFamily: QUICKSAND_FONT,
      },
      text: {
        color: "#ffffff",
        // font: `Bold 12px/15px ${QUICKSAND_FONT}`,

        fontfamily: QUICKSAND_FONT,
      },
      startIcon: {
        color: "#ffffff",
      },
      endIcon: {
        color: "#ffffff",
      },
    },

    MuiTextField: {
      root: {
        fontFamily: QUICKSAND_FONT,
        color: "#707070",
      },
    },

    MuiInputBase: {
      root: {
        fontFamily: QUICKSAND_FONT,
        backgroundColor: "#F8F8F8",
        "&.$Mui-disabled": {
          backgroundColor: "#BCB7B7",
        },
      },
    },

    MuiCheckbox: {
      root: {
        color: "#6AB6B3",
      },
    },
    MuiDrawer: {
      paper: {
        borderTopRightRadius: 50,
        borderBottomRightRadius: 55,
        overflowX: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 30,
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ErrorBoundary className="min-vh-100">
          <Suspense fallback={<LoaderError className="min-vh-100" />}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                maxSnack={3}
              >
                <App />
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();

import React, { useState } from "react";
import {
  makeStyles,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import clsx from "clsx";

import { states } from "../pages/checkout/states";
const useStyles = makeStyles((theme) => ({
  containerDiv: {
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  input1: {
    width: 560,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  pincode: {
    width: 176,
    marginRight: 15,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: 20,
    },
  },
  mobile: {
    width: 361,
    marginRight: 15,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  mobilemsg: {
    marginTop: 30,
    font: `Bold 12px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
  },
  state: {
    width: 176,
    marginRight: 15,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: 15,
    },
  },
  City: {
    width: 176,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  home: {
    width: 176,
    marginRight: 20,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: "40%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  addressHeader: {
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
  },
  titleSelect: {
    width: 232,
    height: 40,
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  menuItem: {
    font: "Bold 16px/20px Quicksand",
    color: theme.palette.text.primary,
  },
  input2: {
    width: 318,
    [theme.breakpoints.down("md")]: {
      width: "67%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%",
      marginTop: 10,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 10,
    },
  },

  mobileDisc: {
    font: `Bold 12px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
  },
  helpertxt: {
    paddingLeft: 10,
  },
}));

const _ = require("lodash");
const AddressForm = (props) => {
  const classes = useStyles();
  const [stateI, setStateI] = useState(-1);
  return (
    <>
      <div className={classes.containerDiv}>
        <span className={classes.addressHeader}>Shipping Address</span>
      </div>
      <div className={classes.containerDiv}>
        <FormControl className={classes.titleSelect}>
          <Select
            className={classes.titleSelect}
            variant="outlined"
            size="small"
            IconComponent={(props) => (
              <img alt="1" src="/assets/textRight.svg" {...props} />
            )}
            defaultValue="-1"
            onChange={(e) => {
              const val = e.target.value;
              // eslint-disable-next-line
              const valid = val != "-1" || props.token;
              props.changeCallback("title", val, valid);

              props.setState({
                errors: { ..._.get(props, ["state", "errors"]), title: valid },
              });
            }}
          >
            <MenuItem className={classes.menuItem} value="-1">
              Title
            </MenuItem>
            <MenuItem className={classes.menuItem} value="Mr">
              Mr
            </MenuItem>
            <MenuItem className={classes.menuItem} value="Mrs">
              Mrs
            </MenuItem>
            <MenuItem className={classes.menuItem} value="Miss">
              Miss
            </MenuItem>
          </Select>

          {!_.get(props, ["state", "errors", "title"]) && (
            <FormHelperText className={classes.helpertxt}>
              Invalid title
            </FormHelperText>
          )}
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          type="text"
          placeholder="Name "
          className={classes.input2}
          required={!props.token}
          error={!_.get(props, ["state", "errors", "name"])}
          helperText={
            !_.get(props, ["state", "errors", "name"]) && "Invalid Name"
          }
          onChange={(e) => {
            const val = e.target.value;
            const valid = e.target.validity.valid;
            props.changeCallback("name", val, valid);
            props.setState({
              errors: { ..._.get(props, ["state", "errors"]), name: true },
            });
          }}
          InputProps={{
            style: {
              color: "#615A5A",
              fontFamily: "Quicksand",
              fontWeight: "bold",
            },
          }}
        />
      </div>
      <div className={classes.containerDiv}>
        <TextField
          variant="outlined"
          size="small"
          type="text"
          required={!props.token}
          placeholder="Address Line 1 "
          error={!_.get(props, ["state", "errors", "add1"])}
          helperText={
            !_.get(props, ["state", "errors", "add1"]) && "Invalid Address 1"
          }
          onChange={(e) => {
            const val = e.target.value;
            const valid = e.target.validity.valid;
            props.changeCallback("add1", val, valid);
            props.setState({
              errors: { ..._.get(props, ["state", "errors"]), add1: true },
            });
          }}
          className={classes.input1}
          InputProps={{
            style: {
              color: "#615A5A",
              fontFamily: "Quicksand",
              fontWeight: "bold",
            },
          }}
        />
      </div>
      <div className={classes.containerDiv}>
        <TextField
          variant="outlined"
          size="small"
          type="text"
          required={!props.token}
          placeholder="Address Line 2 "
          className={classes.input1}
          onChange={(e) => {
            const val = e.target.value;
            // const valid = e.target.validity.valid;
            props.changeCallback("add2", val, true);
          }}
          InputProps={{
            style: {
              color: "#615A5A",
              fontFamily: "Quicksand",
              fontWeight: "bold",
            },
          }}
        />
      </div>
      <div className={classes.containerDiv}>
        <TextField
          variant="outlined"
          size="small"
          type="text"
          placeholder="PINCODE"
          className={classes.pincode}
          required={!props.token}
          error={!_.get(props, ["state", "errors", "pincode"])}
          helperText={
            !_.get(props, ["state", "errors", "pincode"]) && "Invalid pin"
          }
          onChange={(e) => {
            const val = e.target.value;
            const valid = e.target.validity.valid;
            props.changeCallback("pincode", val, valid);
            props.setState({
              errors: { ..._.get(props, ["state", "errors"]), pincode: true },
            });
          }}
          InputProps={{
            style: {
              color: "#615A5A",
              fontFamily: "Quicksand",
              fontWeight: "bold",
            },
          }}
        />

        <FormControl className={classes.state}>
          <Select
            className={classes.state}
            variant="outlined"
            size="small"
            IconComponent={(props) => (
              <img alt="1" src="/assets/textRight.svg" {...props} />
            )}
            autoComplete="off"
            value={props.requestState.inputValues.state}
            onChange={(e) => {
              const val = e.target.value;
              // eslint-disable-next-line
              const valid = val != "-1" || props.token;
              props.changeCallback("state", val, valid);
              setStateI(states.findIndex((i) => i.state === val));
              props.changeCallback("city", -1, false);
              props.setState({
                errors: { ..._.get(props, ["state", "errors"]), city: valid },
              });
            }}
          >
            <MenuItem
              className={classes.menuItem}
              value="-1"
              onClick={() => {
                setStateI(-1);
              }}
            >
              State
            </MenuItem>
            {states.map((item, idx) => {
              return (
                <MenuItem
                  className={classes.menuItem}
                  value={item.state}
                  key={idx}
                  onClick={() => setStateI(idx)}
                >
                  {item.state}
                </MenuItem>
              );
            })}
          </Select>

          {!_.get(props, ["state", "errors", "city"]) && (
            <FormHelperText className={classes.helpertxt}>
              Invalid city
            </FormHelperText>
          )}
        </FormControl>
        <FormControl className={classes.City}>
          <Select
            className={classes.City}
            variant="outlined"
            size="small"
            IconComponent={(props) => (
              <img alt="1" src="/assets/textRight.svg" {...props} />
            )}
            value={props.requestState.inputValues.city}
            required={!props.token}
            onChange={(e) => {
              const val = e.target.value;
              // eslint-disable-next-line
              const valid = val != "-1" || props.token;
              props.changeCallback("city", val, valid);
              props.setState({
                errors: { ..._.get(props, ["state", "errors"]), state: valid },
              });
            }}
          >
            <MenuItem className={classes.menuItem} value="-1">
              City
            </MenuItem>

            {stateI >= 0 &&
              states[stateI].districts.map((item, idx) => {
                return (
                  <MenuItem className={classes.menuItem} value={item} key={idx}>
                    {item}
                  </MenuItem>
                );
              })}
          </Select>

          {!_.get(props, ["state", "errors", "state"]) && (
            <FormHelperText className={classes.helpertxt}>
              Invalid state
            </FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.containerDiv} style={{ display: "flex" }}>
        <Select
          className={classes.home}
          variant="outlined"
          size="small"
          IconComponent={(props) => (
            <img alt="1" src="/assets/textRight.svg" {...props} />
          )}
          defaultValue="Home"
          onChange={(e) => {
            const val = e.target.value;
            props.changeCallback("add_type", val, true);
          }}
        >
          <MenuItem className={classes.menuItem} value="Home">
            Home
          </MenuItem>
          <MenuItem className={classes.menuItem} value="Work">
            Work
          </MenuItem>
        </Select>
        <div>
          <div>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              placeholder="Mobile Number"
              className={classes.mobile}
              required={!props.token}
              value={props.requestState.inputValues.mobile}
              error={!_.get(props, ["state", "errors", "mobile"])}
              helperText={
                !_.get(props, ["state", "errors", "mobile"]) && "Invalid mobile"
              }
              onChange={(e) => {
                const val = e.target.value;
                const valid = e.target.validity.valid;
                props.changeCallback("mobile", val, valid);
                props.setState({
                  errors: {
                    ..._.get(props, ["state", "errors"]),
                    mobile: true,
                  },
                });
              }}
              InputProps={{
                style: {
                  color: "#615A5A",
                  fontFamily: "Quicksand",
                  fontWeight: "bold",
                },
              }}
            />
          </div>
          <div
            className={clsx(classes.mobileDisc, {
              [classes.mobilemsg]: !_.get(props, ["state", "errors", "mobile"]),
            })}
          >
            We might call on this number to find the address.
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressForm;

import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Grid,
  Checkbox,
  TextField,
  Divider,
  Tooltip,
  Select,
  MenuItem,
  CardActionArea,
  AppBar,
  Typography,
} from "@material-ui/core";
import PaymentSummary from "../../components/paymentSummary";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Link } from "react-router-dom";
import BlueBackBtn from "../../components/bluebackbtn";
import withErrorHandler from "../../containers/withErrorHandler";
import axios from "../../axios";
import AlertMessage from "../../containers/Error";
import _ from "lodash";
import * as cardValidator from "card-validator";
import validator from "validator";
import { Months } from "../../GlobalConstants";
import moment from "moment";
import clsx from "clsx";
import HelpIcon from "@material-ui/icons/Help";

const date = moment();
const banks = ["HDFC", "SBIN", "ICIC", "PUNB_R"];

// const _ = require("lodash");
// function loadScript(src) {
//   return new Promise((resolve) => {
//     const script = document.createElement("script");
//     script.src = src;
//     script.onload = () => {
//       resolve(true);
//     };
//     script.onerror = () => {
//       resolve(false);
//     };
//     document.body.appendChild(script);
//   });
// }
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 90,
    [theme.breakpoints.down("xs")]: {
      marginTop: 30,
    },
  },
  leftSection: {
    padding: "50px 50px 10px 12%",

    flexDirection: "column",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0,
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiSelect-select.MuiOutlinedInput-input": {
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.palette.text.primary,
      font: "Bold 16px/20px Quicksand",
    },
    "& .MuiSelect-icon": {
      right: 0,
      top: 0,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "50px 20px 10px 20px",
    },
  },
  headerTxt: {
    font: `Bold 30px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
    marginBottom: 15,
  },
  containerDiv: {
    marginBottom: 15,
    width: 560,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  containerDiv1: {
    width: 560,
    display: "flex",
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "block",
    },
  },
  containerDiv2: {
    width: 406,
    display: "inline-flex",
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  input1: {
    width: 560,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  switchLink: {
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
  },
  slot: {
    width: 267,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: 267,
    },
  },
  home: {
    width: 176,
    marginRight: 20,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: 120,
    },
  },
  emailDisc: {
    font: `Bold 12px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
    marginBottom: 5,
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
    },
  },
  corporateTxt: {
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
  },
  checkBox: {
    fontSize: 30,
    color: theme.palette.primary.main,
  },
  addressHeader: {
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
  },
  titleSelect: {
    width: 232,
    height: 40,
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      width: 150,
    },
  },
  menuItem: {
    font: "Bold 16px/20px Quicksand",
    color: theme.palette.text.primary,
  },
  input2: {
    width: 318,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  input: {
    "&::placeholder": {
      color: theme.palette.text.primary,
      fontWeight: "Bold",
    },
  },
  mobileDisc: {
    font: `Bold 12px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
  },
  tncLink: {
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  tncTxt: {
    font: `Regular 16px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  recievePromTxt: {
    font: `Regular 16px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  labelSelect: {
    display: "flex",
    justifyContent: "space-between",
    width: 200,
  },
  rightSection: {
    [theme.breakpoints.down("md")]: {
      padding: "10px 10px 10px 10px",
    },
  },
  addressPaper: {
    position: "relative",
    width: 559,
    height: 201,
    background: "#F8F8F8 0% 0% no-repeat padding-box",
    borderRadius: 10,
    marginLeft: 10,
  },
  addNewAddressBtn: {
    borderRadius: 20,
    width: 559,
    height: 40,
    margin: "16px 0px 47px 10px",
    font: "Bold 16px/20px Quicksand",
  },
  addressInfo: {
    font: "Bold 16px/20px Quicksand",
    color: "#615A5A",
    margin: "28px 0px 30px 30px",
  },
  addressLabel: {
    color: "#6AB6B3",
    marginBottom: 10,
  },
  addressCornerIcon: {
    position: "absolute",
    right: 13,
    top: 15,
    cursor: "pointer",
  },
  dateBox: {
    "& .MuiInputBase-root": {
      height: 40,
      borderRadius: 7,
    },
    "& .MuiInputBase-input": {
      fontFamily: theme.fontfamily,
      fontWeight: "bold",
      color: theme.palette.text.primary,
    },
    "& .MuiInput-underline:before": {
      borderBottom: "0px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "0px",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0px ",
      borderBottomColor: "#ffffff",
    },
  },
  datepicker: {
    width: 200,
    [theme.breakpoints.down("md")]: {
      width: 300,
      marginBottom: 15,
    },
  },
  newaddbtn: {
    width: "100%",
    height: 40,
    borderRadius: 20,
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.text.secondary,
    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "#a2d8d6",
    },
  },
  valuesdiv: {
    backgroundColor: "#f2f2f2",
    color: theme.palette.text.primary,
    font: "Bold 17px Quicksand",
    borderRadius: 5,
    padding: "5%",
  },
  labeltxt: {
    fontSize: 17,
    fontFamily: theme.fontfamily,
    color: theme.palette.primary.main,
    width: 120,
    textAlign: "right",
    paddingRight: "1%",
  },
  headerTxt: {
    font: `Bold 30px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
    marginBottom: 15,
  },

  checkBox: {
    fontSize: 30,
    color: theme.palette.primary.main,
  },
  methodmainWrapper: {
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
    },
  },
  methodmain: {
    font: "Bold 24px Quicksand",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },

  menuItem: {
    font: "Bold 16px/20px Quicksand",
    color: theme.palette.text.primary,
  },
  input2: {
    width: 318,
    [theme.breakpoints.down("xs")]: {
      width: 220,
    },
  },
  input: {
    "&::placeholder": {
      color: theme.palette.text.primary,
      fontWeight: "Bold",
    },
  },

  rightSection: {
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px 6.5%",
    },
  },
  subSectionSub: { marginTop: 20 },
  subSectionHeading: {
    minWidth: "100%",
    font: "Bold 16px/20px Quicksand",
    color: theme.palette.text.primary,
  },
  cvvHeading: {
    font: "Bold 16px/20px Quicksand",
    color: theme.palette.text.primary,
    marginRight: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      marginRight: 0,
    },
  },
  subSection: {
    padding: 30,

    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  cardNo: {
    width: 350,
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 10,
    },
  },
  cvv: {
    width: 120,
  },
  upi: { width: 250 },
  expiry: {
    width: 120,
    marginRight: 15,
    height: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  netBankingDropdown: {
    width: 350,
    height: 40,
  },
  wallet: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "black",
    height: 100,
    width: 100,
    display: "flex",
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    overflow: "hidden",
    padding: "5px 0px 5px 0px",
    [theme.breakpoints.down("md")]: {
      width: 40,
      height: 40,
    },
  },
  selectedWallet: {
    borderColor: theme.palette.primary.main,
    borderWidth: 5,
  },
  paybtn: {
    marginTop: 30,
    width: 300,
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      width: "97%",
    },
  },
  bankimg: {
    maxHeight: 90,
    maxWidth: 90,
    [theme.breakpoints.down("md")]: {
      width: 40,
    },
  },
}));

let razorpay;

const Checkout = (props) => {
  const [acceptTnc, setAcceptTnc] = useState(false);
  const [leads, setLeads] = useState();
  const [clicks, setClicks] = useState();
  const [email, setEmail] = useState("");
  const payref = useRef();

  const [error, setError] = useState();
  const [method, setMethod] = useState();
  const [supportedMethods, setSupportedMethods] = useState();
  const [selectedWallet, setSelectedWallet] = useState();
  const [upiId, setUpiId] = useState("");
  const [bank, setBank] = useState(0);
  const [cardno, setCardNo] = useState();
  const [cardName, setCardname] = useState();
  const [cvv, setcvv] = useState();
  const [carddMM, setCardMM] = useState(0);
  const [cardYY, setCardYY] = useState(0);
  const [cardtype, setCardtype] = useState();
  const [drop, setDrop] = useState("leads");

  const classes = useStyles();

  useEffect(() => {
    razorpay = new window.Razorpay({
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
    });
    razorpay.once("ready", function (response) {
      setSupportedMethods(response.methods);
    });
  }, [setSupportedMethods]);

  const validations = () => {
    let result = true;
    if (method === "card") {
      if (!cardno) {
        props.enqueueSnackbar("Invalid card number", {
          variant: "error",
        });
        result = false;
      } else {
        const cardLength = cardno.length;
        if (!Array.isArray(_.get(cardtype, ["card", "lengths"]))) {
          props.enqueueSnackbar("Invalid card number", {
            variant: "error",
          });
        } else {
          if (!_.get(cardtype, ["card", "lengths"]).includes(cardLength)) {
            props.enqueueSnackbar("Invalid card number", {
              variant: "error",
            });
          }
        }
      }

      if (!cvv || !validator.isNumeric(cvv)) {
        props.enqueueSnackbar("Invalid cvv", {
          variant: "error",
        });
        result = false;
      } else {
        if (!_.get(cardtype, ["card", "code", "size"])) {
          props.enqueueSnackbar("Invalid card number", {
            variant: "error",
          });
        } else {
          if (cvv.length !== _.get(cardtype, ["card", "code", "size"])) {
            props.enqueueSnackbar("Invalid cvv", {
              variant: "error",
            });
          }
        }
      }
      if (!cardName) {
        props.enqueueSnackbar("Invalid card name", {
          variant: "error",
        });
      }

      const expiryValid = cardValidator.expirationDate(`${carddMM}/${cardYY}`);

      if (!expiryValid.isValid) {
        props.enqueueSnackbar("Invalid expiry date", {
          variant: "error",
        });
      }
    } else if (method === "netbanking") {
      if (!bank || bank === 0) {
        props.enqueueSnackbar("Please choose a bank", {
          variant: "error",
        });
        result = false;
      }
    } else if (method === "wallet") {
      if (!selectedWallet) {
        props.enqueueSnackbar("Please choose a wallet", {
          variant: "error",
        });
        result = false;
      }
    } else if (method === "upi") {
      //const xresult = /^\w+@\w+$/.test(upiId);
      const xresult = /^\b[a-zA-Z0-9.]+@\w+$/.test(upiId);
      if (!xresult) {
        props.enqueueSnackbar("Invalid upi id", {
          variant: "error",
        });
        result = false;
      }
    } else if (method === "paytm") {
    } else {
      props.enqueueSnackbar("Choose a payment method", {
        variant: "error",
      });
      result = false;
    }
    return result;
  };

  const proceedPayment = async () => {
    if (!validations()) {
      return;
    }
    if (!email) {
      setError("Please enter email address");
      return;
    }

    if (!clicks && !leads) {
      setError("Please enter clicks or leads");
      return;
    }

    const amountT = Number(clicks) + Number(leads);

    try {
      // const respSc = await loadScript();

      // console.log(respSc);

      const resp = await axios.post("/initiatepaymentmt", {
        amount: amountT,
      });

      const { amount, id: order_id, currency } = resp.data.message;

      const options = {
        currency,
        amount,
        order_id,
        email,
        method: method,
        contact: "7275203811",
        handler: function (response) {},
      };

      if (method === "card") {
        options.card = {
          number: cardno,
          name: cardName,
          expiry_month: carddMM,
          expiry_year: cardYY,
          cvv: cvv,
        };
      } else if (method === "netbanking") {
        options.bank = bank;
      } else if (method === "wallet") {
        options.wallet = selectedWallet;
      } else if (method === "upi") {
        options.vpa = upiId;
      }

      // const popup = razorpay.createPayment(options);
      if (!payref.current) {
        return;
      }

      const btnFunc = () => {
        razorpay.createPayment(options);
        razorpay.on("payment.success", (resp) => {
          alert("Payment success, you will receive a confirmation email soon");
        });
        razorpay.on("payment.error", (resp) => {
          console.log(resp);
        });
      };
      const btn = payref.current;
      btn.addEventListener("click", btnFunc);
      btn.click();
      btn.removeEventListener("click", btnFunc);
    } catch (error) {
      console.log(error);
    }
  };

  const onCardNoChange = (val) => {
    setCardNo(val);
    const resp = cardValidator.number(val);
    setCardtype(resp);
  };

  const getYears = () => {
    let respArr = [];

    const year = date.format("YY");
    for (let index = 0; index <= 40; index++) {
      respArr.push(
        <MenuItem key={index} value={Number(year) + index}>
          {Number(year) + index}
        </MenuItem>
      );
    }

    return respArr;
  };

  //console.log(requestState.inputValues);

  return (
    <>
      <AppBar>
        <img
          src="https://savemudra.com/img/logo.png"
          alt="logo"
          style={{
            width: 200,
          }}
        />
      </AppBar>

      <Grid container className={classes.root}>
        <button hidden className="btn" ref={payref}>
          This paragraph should be hidden.
        </button>
        <AlertMessage
          errorConfirmedHandler={setError.bind(this, "")}
          error={error}
          severity="error"
        />
        <Grid xs={12} sm={7} item container className={classes.leftSection}>
          <div className={classes.headerTxt}>
            Benefits of Being A Partner with Savemudra
          </div>
          <ul
            style={{
              color: "#615A5A",
              fontFamily: "Quicksand",
              fontWeight: "bold",
            }}
          >
            <li>Get More Exposure</li>
            <li>Reach More Customers</li>
            <li>Draw More Traffic</li>
            <li>Get Repeat Business</li>
          </ul>
          <br />
          <div className={classes.headerTxt}>{"Savemudra Media Buying"}</div>

          <>
            <div className={classes.containerDiv}>
              <Select
                variant="outlined"
                size="small"
                value={drop}
                onChange={(e) => {
                  setDrop(e.target.value);
                }}
                className={classes.input1}
                style={{
                  height: 40,
                }}
                InputProps={{
                  style: {
                    color: "#615A5A",
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                  },
                }}
              >
                <MenuItem value="Impressions">Impressions</MenuItem>
                <MenuItem value="click">Clicks</MenuItem>
                <MenuItem value="leads">Leads</MenuItem>
                <MenuItem value="sales">Sales</MenuItem>
              </Select>
            </div>
            <div className={classes.containerDiv}>
              <TextField
                variant="outlined"
                size="small"
                type="text"
                placeholder="Your E-mail Address* "
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className={classes.input1}
                InputProps={{
                  style: {
                    color: "#615A5A",
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                  },
                }}
              />
            </div>
            <div className={classes.containerDiv}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                placeholder="Total quantity* "
                className={classes.input1}
                value={clicks}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    return;
                  }

                  setClicks(e.target.value);
                }}
                InputProps={{
                  style: {
                    color: "#615A5A",
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                  },
                }}
              />
            </div>
            <div className={classes.containerDiv}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                placeholder="Rate* "
                className={classes.input1}
                value={leads}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    return;
                  }
                  setLeads(e.target.value);
                }}
                InputProps={{
                  style: {
                    color: "#615A5A",
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                  },
                }}
              />
            </div>
            <div className={classes.containerDiv}>
              <div className={classes.emailDisc}>
                We will send your order confirmation to this email address.
              </div>
              <div className={classes.emailDisc}>
                If registered, you will be prompted to enter your your password.
              </div>
            </div>
          </>

          {clicks && leads && (
            <Grid item>
              <div className={classes.headerTxt}>Payment methods</div>
              <Divider />

              {_.get(supportedMethods, ["card"]) && (
                <div className={classes.methodmainWrapper}>
                  <Checkbox
                    size="small"
                    checkedIcon={<CheckBoxIcon className={classes.checkBox} />}
                    icon={
                      <CheckBoxOutlineBlankIcon className={classes.checkBox} />
                    }
                    checked={method === "card"}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      checked && setMethod("card");
                    }}
                    name="card"
                  />
                  <span className={classes.methodmain}>Credit/Debit Card</span>
                  {method === "card" && (
                    <div className={classes.subSection}>
                      <div className={classes.subSectionSub}>
                        <div className={classes.subSectionHeading}>
                          Enter your card number (Visa, Mastercard, Amex)
                        </div>
                        <TextField
                          type="number"
                          className={classes.cardNo}
                          variant="outlined"
                          placeholder="card number"
                          value={cardno}
                          onChange={(e) => {
                            const val = e.target.value;
                            // console.log(val);
                            onCardNoChange(val);
                          }}
                          inputProps={{
                            minLength: 10,
                          }}
                        />
                      </div>
                      <div className={classes.subSectionSub}>
                        <div className={classes.subSectionHeading}>
                          Enter name on card
                        </div>
                        <TextField
                          type="text"
                          className={classes.cardNo}
                          variant="outlined"
                          placeholder="Name"
                          value={cardName}
                          onChange={(e) => {
                            const val = e.target.value;
                            setCardname(val);
                          }}
                        />
                      </div>
                      <div
                        className={classes.subSectionSub}
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span className={classes.cvvHeading}>CVV </span>
                        <TextField
                          type="password"
                          className={classes.cvv}
                          variant="outlined"
                          placeholder="CVV"
                          value={cvv}
                          onChange={(e) => {
                            const val = e.target.value;
                            if (validator.isNumeric(val)) setcvv(val);
                          }}
                          inputProps={{
                            maxLength: 3,
                            minLength: 5,
                          }}
                        />
                        <Tooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <img src="/assets/cvv.png" />
                            </React.Fragment>
                          }
                        >
                          <HelpIcon />
                        </Tooltip>
                      </div>
                      <div
                        className={classes.subSectionSub}
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span className={classes.cvvHeading}>Expires On </span>
                        <Select
                          variant="outlined"
                          value={carddMM}
                          className={classes.expiry}
                          onChange={(e) => {
                            const val = e.target.value;
                            setCardMM(val);
                          }}
                        >
                          <MenuItem value={0}>Month</MenuItem>
                          {Months.map((item, idx) => {
                            return (
                              <MenuItem key={idx} value={item.key}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Select
                          variant="outlined"
                          value={cardYY}
                          className={classes.expiry}
                          onChange={(e) => {
                            const val = e.target.value;
                            setCardYY(val);
                          }}
                        >
                          <MenuItem value={0}>YY</MenuItem>
                          {getYears()}
                        </Select>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {_.get(supportedMethods, ["netbanking"]) && (
                <div className={classes.methodmainWrapper}>
                  <Checkbox
                    size="small"
                    checkedIcon={<CheckBoxIcon className={classes.checkBox} />}
                    icon={
                      <CheckBoxOutlineBlankIcon className={classes.checkBox} />
                    }
                    checked={method === "netbanking"}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      checked && setMethod("netbanking");
                    }}
                    name="netbanking"
                  />
                  <span className={classes.methodmain}>Net Banking</span>
                  {method === "netbanking" && (
                    <>
                      <div
                        className={classes.subSection}
                        style={{ display: "flex" }}
                      >
                        {banks.map((item) => {
                          return (
                            <CardActionArea
                              onClick={() => setBank(item)}
                              className={clsx(classes.wallet, {
                                [classes.selectedWallet]: item === bank,
                              })}
                              key={item}
                            >
                              <img
                                src={`/assets/${item}.jpg`}
                                className={classes.bankimg}
                              />
                            </CardActionArea>
                          );
                        })}
                      </div>
                      <div className={classes.subSection}>
                        <Select
                          variant="outlined"
                          value={bank}
                          className={classes.netBankingDropdown}
                          onChange={(e) => {
                            const val = e.target.value;
                            setBank(val);
                          }}
                        >
                          <MenuItem value={0}>Bank</MenuItem>
                          {Object.keys(
                            _.get(supportedMethods, ["netbanking"])
                          ).map((item, idx) => {
                            return (
                              <MenuItem value={item}>
                                {_.get(supportedMethods, ["netbanking", item])}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </>
                  )}
                </div>
              )}
              {_.get(supportedMethods, ["wallet"]) && (
                <div className={classes.methodmainWrapper}>
                  <Checkbox
                    size="small"
                    checkedIcon={<CheckBoxIcon className={classes.checkBox} />}
                    icon={
                      <CheckBoxOutlineBlankIcon className={classes.checkBox} />
                    }
                    checked={method === "wallet"}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      checked && setMethod("wallet");
                    }}
                    name="wallet"
                  />
                  <span className={classes.methodmain}>Other Wallets</span>
                  {method === "wallet" && (
                    <div
                      className={classes.subSection}
                      style={{ display: "flex" }}
                    >
                      {Object.keys(_.get(supportedMethods, ["wallet"])).map(
                        (item, idx) => {
                          if (!_.get(supportedMethods, ["wallet", item])) {
                            return;
                          }
                          return (
                            <CardActionArea
                              onClick={() => setSelectedWallet(item)}
                              className={clsx(classes.wallet, {
                                [classes.selectedWallet]:
                                  item === selectedWallet,
                              })}
                              key={idx}
                            >
                              <img
                                src={`/assets/${item}.jpg`}
                                className={classes.bankimg}
                              />
                            </CardActionArea>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              )}
              {_.get(supportedMethods, ["upi"]) && (
                <div className={classes.methodmainWrapper}>
                  <Checkbox
                    size="small"
                    checkedIcon={<CheckBoxIcon className={classes.checkBox} />}
                    icon={
                      <CheckBoxOutlineBlankIcon className={classes.checkBox} />
                    }
                    name="upi"
                    checked={method === "upi"}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      checked && setMethod("upi");
                    }}
                  />
                  <span className={classes.methodmain}>UPI</span>
                  {method === "upi" && (
                    <div className={classes.subSection}>
                      <div
                        className={classes.subSectionSub}
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span className={classes.cvvHeading}>UPI ID</span>
                        <TextField
                          type="text"
                          className={classes.upi}
                          variant="outlined"
                          value={upiId}
                          placeholder="example@bank"
                          onChange={(e) => {
                            const val = e.target.value;
                            setUpiId(val);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Grid>
          )}
          {clicks && leads && (
            <div className={classes.containerDiv} style={{ display: "flex" }}>
              <BlueBackBtn
                width={180}
                radius={33}
                label="Proceed to pay"
                right={85}
                action={proceedPayment}
              />
            </div>
          )}
        </Grid>
        <Grid xs={12} sm={5} item container className={classes.rightSection}>
          <PaymentSummary clicks={clicks} leads={leads} />
        </Grid>
      </Grid>
    </>
  );
};

export default withErrorHandler(Checkout, axios);

import React, { useEffect, useState } from "react";

import Loader from "./Loader";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { successMessages } from "./successMessages";

const _ = require("lodash");
let reqInterceptor;
let resIntercepter;
const withErrorHandler = (WrappedComponent, axios) => {
  return (props) => {
    const [errorObj, setErrorObj] = useState({
      error: false,
      message: null,
    });
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState();
    useEffect(() => {
      reqInterceptor = axios.interceptors.request.use((req) => {
        setErrorObj({
          error: false,
          message: null,
        });
        setSuccess("");
        setOpen(true);
        return req;
      });
      resIntercepter = axios.interceptors.response.use(
        (res) => {
          const id = _.get(res, ["config", "headers", "id"]);
          const type = _.get(res, ["config", "method"]);
          let msg;
          if (id && type) {
            msg = _.get(successMessages, [id, type]);
          }
          if (msg) {
            setSuccess(msg);
          }

          setOpen(false);
          return res;
        },
        (err) => {
          setOpen(false);
          setErrorObj({
            error: true,
            message:
              _.get(err, ["response", "data", "message"]) ||
              "Problem occured, please try again later",
          });
          return Promise.reject(err);
        }
      );

      return () => {
        axios.interceptors.request.eject(reqInterceptor);
        axios.interceptors.response.eject(resIntercepter);
      };
    }, [setErrorObj, setOpen, setSuccess]);

    const errorConfirmedHandler = () => {
      setErrorObj({
        error: false,
        message: null,
      });
    };

    const successConfirmedHandler = () => {
      setSuccess("");
    };

    return (
      <>
        <Loader
          open={open}
          setOpen={setOpen}
          action={() => {
            setOpen(false);
          }}
        />
        <Snackbar
          open={errorObj.error}
          autoHideDuration={6000}
          onClose={errorConfirmedHandler}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={errorConfirmedHandler} severity="error">
            {errorObj.message}
          </Alert>
        </Snackbar>
        <Snackbar
          open={success ? true : false}
          autoHideDuration={6000}
          onClose={successConfirmedHandler}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={successConfirmedHandler} severity="success">
            {success}
          </Alert>
        </Snackbar>
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withErrorHandler;

import React, { createRef, useEffect } from "react";

import { Switch, withRouter, Route } from "react-router-dom";
import Navbar from "./components/navbar";

import scrollToComponent from "react-scroll-to-component";
import ScrollToTop from "./ScrollToTop";
import Checkout from "./pages/checkout";
import ForgotPassword from "./pages/forgotpassword";
import Payment from "./pages/payment";
import { useDispatch } from "react-redux";
import * as CartActions from "./store/actions/cart";
const Home = React.lazy(() => import("./pages/home/index"));
const Category = React.lazy(() => import("./pages/category/index"));
const Occasions = React.lazy(() => import("./pages/occasions/index"));
const Item = React.lazy(() => import("./pages/item"));
const Login = React.lazy(() => import("./pages/login"));
const Signup = React.lazy(() => import("./pages/signup"));
const OccasionThankyou = React.lazy(() =>
  import("./pages/thankyou/occasionthankyou")
);
const Thankyou = React.lazy(() => import("./pages/thankyou"));
const AboutUs = React.lazy(() => import("./pages/home/team"));
const Cart = React.lazy(() => import("./pages/cart"));
const Tnc = React.lazy(() => import("./pages/tncs"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacypolicy"));
const ShippingPolicy = React.lazy(() => import("./pages/shippingpolicy"));
const CancellationPolicy = React.lazy(() =>
  import("./pages/cancellationpolicy")
);
const Profile = React.lazy(() => import("./pages/profile"));
const Orders = React.lazy(() => import("./pages/orders"));
const Blogs = React.lazy(() => import("./pages/blogs"));
const Blog = React.lazy(() => import("./pages/blogs/blog"));
const Diwali = React.lazy(() => import("./pages/diwali"));

function App() {
  const dispatch = useDispatch();
  const queryRef = createRef();
  const focusonQueryForm = () => {
    if (!queryRef.current) return;
    scrollToComponent(queryRef.current, {
      offset: 0,
      align: "center",
      duration: 1000,
    });
  };

  useEffect(() => {
    let cart = localStorage.getItem("cart");
    if (!cart) {
      return;
    }
    cart = JSON.parse(cart);
    localStorage.setItem("reload", true);
    let action = CartActions.add(cart);
    dispatch(action);
  }, []);
  return (
    <ScrollToTop>
      {/* <Navbar focusonQueryForm={focusonQueryForm} /> */}
      <Switch>
        <Route path="/" component={Checkout} />
        {/* <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={Signup} />
        <Route path="/payment" exact component={Payment} />
        <Route path="/thankyou" exact component={Thankyou} />
        <Route path="/othankyou" exact component={OccasionThankyou} />
        <Route path="/blogs" exact component={Blogs} />
        <Route path="/blogs/:id" exact component={Blog} />

        <Route path="/forgotpassword" exact component={ForgotPassword} />
        <Route path="/category/:code" exact component={Category} />
        <Route path="/occasions/:id" exact component={Occasions} />
        <Route path="/item/:productId" exact component={Item} />
        <Route path="/cart" exact component={Cart} /> */}
        {/*  */}
        {/* <Route path="/tnc" exact component={Tnc} />
        <Route path="/aboutus" exact component={AboutUs} />

        <Route path="/shippingpolicy" exact component={ShippingPolicy} />
        <Route path="/privacypolicy" exact component={PrivacyPolicy} />

        <Route path="/canellationpolicy" exact component={CancellationPolicy} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/address" exact component={Profile} />
        <Route path="/orders" exact component={Orders} />
        <Route path="/diwali" exact component={Diwali} /> */}

        {/* <Route
          path="/"
          component={(props) => (
            <Home
              {...props}
              ref={queryRef}
              focusonQueryForm={focusonQueryForm}
            />
          )}
        /> */}
      </Switch>
    </ScrollToTop>
  );
}

export default withRouter(App);

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import FreeDelivery from "./freeDelivery";

import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  paymentSummary: {
    width: 395,
    "& .MuiListItem-gutters": {
      paddingLeft: 0,
    },
    "& .MuiListItemText-primary": {
      font: "Bold 20px/25px Quicksand",
      color: theme.palette.text.primary,
    },
    "& .MuiListItemSecondaryAction-root": {
      font: "Bold 20px/25px Quicksand",
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("sm")]: {
      width: 320,
    },
  },
  paymentSummaryTitle: {
    font: "Bold 38px Quicksand",
    margin: 0,
    color: "#6AB6B3",
    marginTop: 65,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },
  },
  paymentSummaryDivider: {
    height: 3,
    background: "#6AB6B3",
  },
  links: {
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
}));

const PaymentSummary = (props) => {
  const { leads = 0, clicks = 0 } = props;
  const classes = useStyles();

  const currency = "₹";

  const total = Number(leads) * Number(clicks);

  return (
    <div className={classes.root}>
      <div>
        <p className={classes.paymentSummaryTitle}>Payment Summary</p>
        <List className={classes.paymentSummary}>
          {/* <Divider className={classes.paymentSummaryDivider} />
          <ListItem>
            <ListItemText>Leads(0.1){currency} per lead</ListItemText>
            <ListItemSecondaryAction>
              {leadCost}
              {currency}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText>Clicks(0.2){currency} per lead</ListItemText>
            <ListItemSecondaryAction>
              {clickCost}
              {currency}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider /> */}

          <ListItem>
            <ListItemText>
              <span style={{ color: "#6AB6B3" }}>TOTAL</span>
            </ListItemText>
            <ListItemSecondaryAction>
              {total.toFixed(2)}
              {currency}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default PaymentSummary;

import { combineReducers } from "redux";
import auth from "./store/reducers/auth";

import { LOGOUT_ACTION } from "./GlobalConstants";
import cart from "./store/reducers/cart";
import coupon from "./store/reducers/coupon";
import paymentrequest from "./store/reducers/paymentrequest";

const appReducer = combineReducers({
  auth: auth,
  cart: cart,
  coupon: coupon,
  paymentrequest: paymentrequest,
});

export const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === LOGOUT_ACTION) {
    state = undefined;
  }

  return appReducer(state, action);
};

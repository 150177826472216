export const ADD_ACTION = "ADD_CART";
export const UPDATE_ACTION = "UPDATE_CART";
export const DELETE_ACTION = "DELETE_CART";
export const DELETEALL_ACTION = "DELETEALL_CART";

export const add = (data) => {
  return (dispatch) => {
    localStorage.setItem("cart", JSON.stringify(data));

    dispatch({
      type: ADD_ACTION,
      data,
    });
  };
};

export const update = (data) => {
  return (dispatch) => {
    localStorage.setItem("cart", JSON.stringify(data));

    dispatch({
      type: UPDATE_ACTION,
      data,
    });
  };
};

export const del = (data) => {
  return (dispatch) => {
    localStorage.setItem("cart", JSON.stringify({}));

    dispatch({
      type: DELETE_ACTION,
      data,
    });
  };
};

import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useWindowSize } from "react-use";

const useStyles = makeStyles((theme) => ({
  btn: {
    height: 40,
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.text.secondary,
    "&.MuiButton-root.Mui-disabled":{
      backgroundColor: '#a2d8d6',
    }
  },
}));

const BlueBackBtn = (props) => {
  const classes = useStyles();
  const { width } = useWindowSize();
  return (
    <Button
      className={classes.btn}
      disabled={props.disabled}
      style={{
        marginLeft: props.left || 0,
        marginRight: width < 500 ? props.right / 2 || 0 : props.right || 0,
        width: props.width || 170,
        borderRadius: props.radius || 40,
      }}
      onClick={props.action}
      type={props.type || "sumbit"}
    >
      {props.label}
    </Button>
  );
};

export default BlueBackBtn;

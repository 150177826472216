import { ADD_ACTION, UPDATE_ACTION, DELETE_ACTION } from "../actions/coupon";

const coupon = {
  code: "",
  discount: "",
};

export default (state = coupon, action) => {
  switch (action.type) {
    case ADD_ACTION:
      return action.data;
    case UPDATE_ACTION:
      return action.data;
    case DELETE_ACTION:
      return {
        code: "",
        discount: "",
      };
    default:
      return state;
  }
};

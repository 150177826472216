export const ADD_ACTION = "ADD_PAYREQUEST";
export const DELETE_ACTION = "DELETE_PAYREQUEST";
export const DELETEALL_ACTION = "DELETEALL_PAYREQUEST";

export const add = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_ACTION,
      data,
    });
  };
};

export const del = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_ACTION,
    });
  };
};

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  List,
  Divider,
  ListItem,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import EmptyCart from "./emptycart";
import ApplyCoupon from "./applyCoupon";
import FreeDelivery from "./freeDelivery";
import CloseIcon from "@material-ui/icons/Close";
import * as CartActions from "../store/actions/cart";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import * as CouponActions from "../store/actions/coupon";
import { useWindowSize } from "react-use";

const _ = require("lodash");
const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: 20,
  },
  root: {
    width: 420,
    minHeight: "90vh",
    overflow: "auto",
    position: "relative",
    padding: "10px 40px 40px 40px",
    "& .MuiListItem-gutters": {
      padding: 0,
    },

    [theme.breakpoints.down("xs")]: {
      maxWidth: "80vw",
      padding: "10px 40px 40px 32px",
    },
  },
  labelSection: {
    borderBottom: `solid 2px ${theme.palette.primary.main}`,
    margin: "0px 0px 10px 0px",
  },
  headingTxt: {
    font: `Bold 24px ${theme.fontfamily}`,
    color: theme.palette.primary.main,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
    },
  },
  discountTxt: {
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  removeIcon: {
    backgroundColor: "#DEEDF0",

    marginTop: 7,
    color: "#6AB6B3",
    borderRadius: 0,
    "&:hover": {
      background: "#DEEDF0 0% 0% no-repeat padding-box",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  cartItemList: {
    marginTop: 20,
  },
  listDivider: {
    height: 0,
    border: "1px solid #E2E2E2",
  },
  itemDescription: {
    margin: "10px 16px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 0px 0px 3px",
    },
  },
  itemName: {
    font: "Bold 20px/25px Quicksand",
    color: "#615A5A",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  itemEditLink: {
    marginLeft: 12,
    textDecoration: "underline",
    font: "Bold 15px/19px Quicksand",
    color: "#6AB6B3",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  itemPrice: {
    marginTop: 10,
    font: "Bold 20px/25px Quicksand",
    color: "#6AB6B3",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  itemSugar: {
    font: "Bold 15px/19px Quicksand",
    color: "#6AB6B3",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  listItem: {
    height: 84,
    "& .MuiGrid-container": {
      height: "100%",
    },
  },
  freeDelivery: {
    margin: "71px 0px",
  },
  lastDivider: {
    borderBottom: "3px solid #6AB6B3",
    height: 0,
  },
  subTotal: {
    marginTop: 17,
    font: "Bold 20px/25px Quicksand",
    color: "#6AB6B3",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  shoppingCartLink: {
    marginTop: 18,
  },
  checkoutBtn: {
    position: "fixed",
    bottom: 0,
    width: 500,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    height: 71,
    font: "Bold 20px/25px Quicksand",
    color: "#FFFFFF",
  },
}));

const Cart = (props) => {
  const cart = useSelector((state) => state.cart);
  const coupon = useSelector((state) => state.coupon);
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const classes = useStyles();

  const removeCartItem = (id) => {
    if (cart[id]) {
      let cartObj = { ...cart };
      delete cartObj[id];
      let action = CartActions.del(cartObj);
      dispatch(action);
      let couponAction = CouponActions.del();
      dispatch(couponAction);
    }
  };
  const currency = "₹";
  const [paymentSummary, setPaymentSummary] = useState({
    totalItemAmount: 0,
    totalDiscount: 0,
    totalAmount: 0,
  });

  useEffect(() => {
    if (Object.keys(cart).length > 0) {
      let totalItemAmount = 0;
      let totalDiscount = 0;
      let totalAmount = 0;
      // eslint-disable-next-line
      Object.entries(cart).map((item) => {
        item = item[1];

        totalItemAmount +=
          (Number(item.discounted_price) + Number(item.sugarExtra)) *
          Number(item.quantity);
        if (item.total_discount) {
          // totalDiscount += Number(item.total_discount) * Number(item.quantity);
        }
      });

      totalAmount =
        Number(totalItemAmount) - Number(_.get(coupon, "discount") || 0);

      setPaymentSummary({
        totalItemAmount,
        totalDiscount,
        totalAmount,
      });
    }
  }, [cart, coupon]);

  if (Object.keys(cart).length < 1) {
    return (
      <EmptyCart history={props.history} closeCartRef={props.closeCartRef} />
    );
  }
  return (
    <div className={classes.rootContainer}>
      <div className={classes.root}>
        <div className={classes.labelSection}>
          <span className={classes.headingTxt}>Your Cart </span>
        </div>
        <ApplyCoupon paymentSummary={paymentSummary} />
        <List className={classes.cartItemList}>
          <Divider className={classes.listDivider} />
          {Object.entries(cart).map((item, index) => {
            item = item[1];
            return (
              <div key={index}>
                <ListItem index={index} className={classes.listItem}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Grid container>
                        <Grid item>
                          <IconButton
                            className={classes.removeIcon}
                            onClick={() => removeCartItem(item.id)}
                            size={width > 500 ? "medium" : "small"}
                            style={{
                              marginTop: width < 500 && 4,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <div className={classes.itemDescription}>
                            <span className={classes.itemName}>
                              {item.product_name} | {item.unit_qty}{" "}
                              {item.unit_name}
                            </span>
                            <NavLink
                              to="/cart"
                              onClick={() => {
                                props.closeCartRef.current.click();
                              }}
                            >
                              <span className={classes.itemEditLink}>Edit</span>
                            </NavLink>
                            <div className={classes.itemSugar}>
                              {item.sugar}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <div className={classes.itemPrice}>
                        {currency}
                        {(Number(item.discounted_price) +
                          Number(item.sugarExtra)) *
                          Number(item.quantity)}
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider
                  className={
                    index + 1 === Object.keys(cart).length
                      ? classes.lastDivider
                      : classes.listDivider
                  }
                />
              </div>
            );
          })}
          <Grid container className={classes.subTotal} justify="space-between">
            <Grid item>
              <div>Sub Total :</div>
            </Grid>
            <Grid item>
              {coupon.discount && (
                <div style={{ textDecoration: "line-through" }}>
                  {currency}
                  {paymentSummary.totalAmount + coupon.discount}
                </div>
              )}
              <div>
                {currency}
                {paymentSummary.totalAmount}
              </div>
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Grid item>
              <NavLink
                to="/cart"
                onClick={() => {
                  props.closeCartRef.current.click();
                }}
              >
                <div
                  className={clsx(
                    classes.itemEditLink,
                    classes.shoppingCartLink
                  )}
                >
                  View Shopping Cart
                </div>
              </NavLink>
            </Grid>
          </Grid>
          <div className={classes.freeDelivery}>
            <FreeDelivery total={paymentSummary.totalAmount} />
          </div>
        </List>
      </div>
      <Button
        className={classes.checkoutBtn}
        onClick={() => {
          props.closeCartRef.current.click();
          props.history.push("/checkout");
        }}
      >
        Checkout
      </Button>
    </div>
  );
};

export default Cart;

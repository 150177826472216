import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  CardActionArea,
  MenuItem,
  Select,
  Button,
  Typography,
  Drawer,
  ListItem,
  IconButton,
  Popover,
  Hidden,
  Menu,
  Divider,
  Collapse,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import { LOGOUT_ACTION } from "../GlobalConstants";
import { withRouter, Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { useSelector, useDispatch } from "react-redux";
import LoginCallout from "./logincallout";
import Cart from "./cart";
import _ from "lodash";
import axios from "../axios";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./navbar.css";
const useStyles = makeStyles((theme) => ({
  root: {
    height: 60,
    //

    backgroundColor: theme.palette.secondary.light,
    [theme.breakpoints.down("xs")]: {
      boxShadow: "0 10px 10px -10px #000000",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      overflow: "hidden",
    },
    [theme.breakpoints.up("sm")]: {
      height: 80,
      paddingTop: 10,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "5%",
      paddingRight: "5%",
      backgroundColor: theme.palette.secondary.light,
    },
  },
  toolBarWeb: {
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logoAction: {
    width: "inherit",
    //borderRadius: "50%",
    marginRight: "2%",
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
    marginRight: "2%",
    marginLeft: "2%",
    font: `bold 16px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      font: `bold 12px ${theme.fontfamily}`,
      marginLeft: "0%",
    },
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.text.secondary,
      marginLeft: "2%",
    },
  },

  subText: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 50,
      font: `bold 12px ${theme.fontfamily}`,
      color: theme.palette.text.secondary,
    },
  },
  productSelect: {
    marginRight: "2%",
    marginLeft: "2%",
    font: `Bold 16px ${theme.fontfamily}`,
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    [theme.breakpoints.down("xs")]: {
      width: 132,
      color: theme.palette.text.secondary,
    },
    height: 36,
    "&:before": {
      borderBottomWidth: 0,
    },
    "&:after": {
      borderBottomWidth: 0,
    },
  },
  menuItem: {
    font: `Bold 16px ${theme.fontfamily}`,
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
    },
    //color: "#615A5A",
  },
  bulkBtn: {
    borderRadius: 40,
    padding: "10px 10px 10px 10px",
    marginRight: "2%",
    [theme.breakpoints.down("md")]: {
      borderRadius: 20,
      height: 40,
      fontSize: 10,
    },
    [theme.breakpoints.down("sm")]: {
      borderColor: theme.palette.secondary.light,
      borderWidth: 2,
      borderStyle: "solid",
      padding: "7px 10px 7px 10px",
      fontSize: 14,
      fontFamily: theme.fontfamily,
      fontWeight: "bold",
    },
  },
  loginbtn: {
    borderRadius: 40,
    borderWidth: 2,
    borderStyle: "solid",
    padding: "7px 10px 7px 10px",
    backgroundColor: theme.palette.text.secondary,
  },
  loginbtnTxt: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: theme.fontfamily,
    fontWeight: "bold",
  },
  cartCount: {
    position: "absolute",
    backgroundColor: "#FF8080",
    width: 20,
    height: 20,
    borderRadius: "50%",
    top: 0,
    left: 25,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  toolBarMobile: {
    justifyContent: "space-between",
    alignContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  list: {
    width: 250,
    height: "100vh",
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  fullList: {
    width: "auto",
    position: "relative",
  },
  closeCartButton: { position: "absolute", right: 5, top: 5 },
  logo: {
    height: 65,
    width: 196,
    [theme.breakpoints.down("sm")]: { height: 41, width: 125 },
  },
  popover: {
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
    "& .MuiPaper-elevation8": {
      [theme.breakpoints.up("sm")]: {
        boxShadow: "0 0 0 0",
      },
    },
  },
  profileMenu: {
    "& .MuiMenu-paper": {
      width: 195,
      maxHeight: 200,
      marginTop: 60,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 25,
    },
    "& .MuiMenuItem-root": {
      font: "Bold 14px/18px Quicksand",
      color: "#707070",
      height: 37,
    },
  },
  cartMob: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      overflowX: "hidden",
    },
  },
  btnPopover: {
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    marginRight: "2%",
    marginLeft: "2%",
    display: "flex",
    alignItems: "center",
  },
  listItemContent: {
    color: theme.palette.secondary.light,
    fontSize: 20,
  },
  listItemIcon: {
    color: theme.palette.secondary.light,
    fontSize: 25,
    minWidth: 30,
  },
  nameF: {
    font: "Bold 20px Quicksand",
    height: 40,
    width: 40,
    borderRadius: 40,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  nameFl: {
    font: "Bold 16px Quicksand",
    color: theme.palette.text.primary,
    paddingLeft: 3,
  },
}));

const Navbar = (props) => {
  const [state, setState] = useState({
    left: false,
  });
  const [cartDrawer, setCartDrawer] = useState({
    right: false,
  });
  const cart = useSelector((state) => state.cart);
  const [anchorElPop, setAnchorElPop] = React.useState(null);
  const [anchorElPopMob, setAnchorElPopMob] = React.useState(null);
  const [occasions, setOccasions] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElOccasions, setAnchorElOccasions] = React.useState(null);
  const [anchorElSweets, setAnchorElSweets] = React.useState(null);

  const [cartSize, setCartSize] = useState(Object.keys(cart).length);
  // eslint-disable-next-line
  const auth = useSelector((state) => state.auth);

  const token = localStorage.getItem("token");
  let profile = localStorage.getItem("profile");
  if (profile) profile = JSON.parse(profile);
  const dispatch = useDispatch();
  const [openCollapseSweets, setOpenCollapseSweets] = useState();
  const [openCollapseOccasions, setOpenCollapseOccasions] = useState();
  const [openCollapseProfile, setOpenCollapseProfile] = useState();
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickOccasionMenu = (event) => {
    setAnchorElOccasions(event.currentTarget);
  };

  const handleClickSweetsMenu = (event) => {
    setAnchorElSweets(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseOccasionMenu = () => {
    setAnchorElOccasions(null);
  };

  const handleCloseSweetMenu = () => {
    setAnchorElSweets(null);
  };

  const fetchOccasionData = useCallback(
    async (id) => {
      try {
        const response = await axios.get(`occasions`);
        let { data } = response;
        setOccasions(_.get(data, ["message"], []));
      } catch (error) {
        console.log(error);
      }
    },
    [setOccasions]
  );

  useEffect(() => {
    let id = _.get(props, ["match", "params", "id"], "");
    fetchOccasionData(id);
  }, [fetchOccasionData, props]);

  const closeCartRef = useRef();
  const cartRef = useRef();
  const handleClickPop = (event) => {
    setAnchorElPop(event.currentTarget);
  };
  const handleClickPopMob = (event) => {
    setAnchorElPopMob(event.currentTarget);
  };

  const handleClosePop = () => {
    setAnchorElPop(null);
  };

  const handleClosePopMob = () => {
    setAnchorElPopMob(null);
  };
  const classes = useStyles();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const toggleDrawerManual = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const toggleCartDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown") {
      return;
    }

    setCartDrawer({ ...cartDrawer, [anchor]: open });
  };
  useEffect(() => {
    if (Object.keys(cart).length != cartSize) {
      setCartSize(Object.keys(cart).length);
    }
  }, [cart, cartSize, setCartSize]);

  useEffect(() => {
    if (props.history.location.pathname === "/cart") {
      return;
    }
    const reload = JSON.parse(localStorage.getItem("reload"));
    if (reload) {
      localStorage.setItem("reload", false);
      return;
    }
    let cartCount = Object.keys(cart).length;
    if (cartCount > 0 && cart) {
      if (cartRef.current) {
        cartRef.current.click();
      }
    }
  }, [cartRef, cart]);

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem
          button
          onClick={() => setOpenCollapseSweets((prevValue) => !prevValue)}
          /* className={clsx(classes.listItem, {
                [classes.itemSelected]: selectedItem === 1,
              })} */
        >
          <ListItemText
            primary="Sweets"
            className={classes.link}
            disableTypography
          />
          <ListItemIcon>
            {openCollapseSweets ? (
              <KeyboardArrowUpIcon className={classes.listItemIcon} />
            ) : (
              <KeyboardArrowDownIcon className={classes.listItemIcon} />
            )}
          </ListItemIcon>
        </ListItem>
        <Collapse in={openCollapseSweets} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                onCategoryChange(
                  "subCategories?sub=ghee",
                  "All Ghee Sweets",
                  "ghee"
                );
              }}
              /* className={clsx(classes.listItem, {
                [classes.itemSelected]: selectedItem === 1,
              })} */
            >
              <ListItemText
                primary="Ghee sweets"
                className={classes.subText}
                disableTypography
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                onCategoryChange(
                  "subCategories?sub=khoya",
                  "All Khoya Sweets",
                  "khoya"
                );
              }}
              /* className={clsx(classes.listItem, {
                [classes.itemSelected]: selectedItem === 1,
              })} */
            >
              <ListItemText
                primary="Khoya sweets"
                className={classes.subText}
                disableTypography
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                onCategoryChange(
                  "subCategories?sub=bengali",
                  "All Chena Sweets",
                  "bengali"
                );
              }}
              /* className={clsx(classes.listItem, {
                [classes.itemSelected]: selectedItem === 1,
              })} */
            >
              <ListItemText
                primary="Chena sweets"
                className={classes.subText}
                disableTypography
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                onCategoryChange(
                  "subCategories?sub=dry",
                  "All Dry Fruits Sweets",
                  "dry"
                );
              }}
              /* className={clsx(classes.listItem, {
                [classes.itemSelected]: selectedItem === 1,
              })} */
            >
              <ListItemText
                primary="Dry Fruits sweets"
                className={classes.subText}
                disableTypography
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                onCategoryChange(
                  "subCategories?sub=other",
                  "ALl Other Sweets",
                  "other"
                );
              }}
              /* className={clsx(classes.listItem, {
                [classes.itemSelected]: selectedItem === 1,
              })} */
            >
              <ListItemText
                primary="Other Sweets"
                className={classes.subText}
                disableTypography
              />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button key={"bestsellers"}>
          <Link
            to={{
              pathname: "/category/subCategories?sub=savories",
              state: {
                heading: "All Savouries",
                code: "savories",
              },
            }}
            onClick={toggleDrawer(anchor, false)}
            className={classes.link}
          >
            Savouries
          </Link>
        </ListItem>
        <ListItem button key={"dry"}>
          <Link
            to={{
              pathname: "/category/subCategories?sub=dry-fruits",
              state: {
                heading: "All Dry Fruits",
                code: "dry-fruits",
              },
            }}
            onClick={toggleDrawer(anchor, false)}
            className={classes.link}
          >
            Dry Fruits
          </Link>
        </ListItem>

        <ListItem button key={"dessert"}>
          <Link
            to={{
              pathname: "/category/subCategories?sub=dessert",
              state: {
                heading: "All Dessert",
                code: "dessert",
              },
            }}
            onClick={toggleDrawer(anchor, false)}
            className={classes.link}
          >
            Desserts
          </Link>
        </ListItem>
        <ListItem
          button
          onClick={() => setOpenCollapseOccasions((prevValue) => !prevValue)}
        >
          <ListItemText
            primary="Occasions"
            className={classes.link}
            disableTypography
          />
          <ListItemIcon>
            {openCollapseOccasions ? (
              <KeyboardArrowUpIcon className={classes.listItemIcon} />
            ) : (
              <KeyboardArrowDownIcon className={classes.listItemIcon} />
            )}
          </ListItemIcon>
        </ListItem>
        <Collapse in={openCollapseOccasions} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {occasions.map((data, idx) => (
              <ListItem
                key={idx}
                button
                onClick={() => {
                  toggleDrawerManual(anchor, false);
                  onOccasionChange(data.id);
                }}
              >
                <ListItemText
                  primary={data.name}
                  className={classes.subText}
                  disableTypography
                />
              </ListItem>
            ))}
          </List>
        </Collapse>

        <ListItem button key={"bulk"}>
          <Button
            className={classes.bulkBtn}
            onClick={() => {
              toggleDrawerManual(anchor, false);
              onOccasionChange("1");
            }}
          >
            Bulk Order
          </Button>
        </ListItem>
        {!token && (
          <ListItem button key={"login"}>
            <Button
              className={classes.loginbtn}
              onClick={handleClickPopMob}
              aria-describedby="popover"
            >
              <Typography className={classes.loginbtnTxt}>
                Login/Signup
              </Typography>
            </Button>
          </ListItem>
        )}
        <Popover
          id="popover"
          anchorEl={anchorElPopMob}
          open={Boolean(anchorElPopMob)}
          onClose={handleClosePopMob}
          className={classes.popover}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 50, left: 50 }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {/* {fvf} */}
          <LoginCallout
            history={props.history}
            handleClose={() => {
              handleClosePopMob();
              toggleDrawerManual(anchor, false);
            }}
          />
        </Popover>
        {token && (
          <ListItem
            button
            onClick={() => setOpenCollapseProfile((prevValue) => !prevValue)}
          >
            <ListItemText
              primary="Profile"
              className={classes.link}
              disableTypography
            />
            <ListItemIcon>
              {openCollapseProfile ? (
                <KeyboardArrowUpIcon className={classes.listItemIcon} />
              ) : (
                <KeyboardArrowDownIcon className={classes.listItemIcon} />
              )}
            </ListItemIcon>
          </ListItem>
        )}
        <Collapse in={openCollapseProfile} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              key={5}
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                props.history.push("/orders");
              }}
            >
              <ListItemText
                primary={"My Orders"}
                className={classes.subText}
                disableTypography
              />
            </ListItem>
            <ListItem
              key={3}
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                props.history.push("/profile");
              }}
            >
              <ListItemText
                primary={"Profile"}
                className={classes.subText}
                disableTypography
              />
            </ListItem>
            <ListItem
              key={4}
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                props.history.push("/address");
              }}
            >
              <ListItemText
                primary={"Addresses"}
                className={classes.subText}
                disableTypography
              />
            </ListItem>
            <ListItem
              key={1}
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                onOccasionChange("1");
              }}
            >
              <ListItemText
                primary={"Corporate Enquiry"}
                className={classes.subText}
                disableTypography
              />
            </ListItem>
            <ListItem
              key={2}
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                if (props.history.location.pathname !== "/") {
                  props.history.push({
                    pathname: "/",
                    state: {
                      focusonQueryForm: true,
                    },
                  });
                } else {
                  props.focusonQueryForm();
                }
              }}
            >
              <ListItemText
                primary={"Help and Support"}
                className={classes.subText}
                disableTypography
              />
            </ListItem>
            <ListItem
              key={3}
              button
              onClick={() => {
                toggleDrawerManual(anchor, false);
                setOpenCollapseProfile((prevValue) => !prevValue);
                logoutHandler();
              }}
            >
              <ListItemText
                primary={"Logout"}
                className={classes.subText}
                disableTypography
              />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </div>
  );

  const listCart = (anchor) => (
    <div
      className={clsx(classes.fullList)}
      role="presentation"
      onKeyDown={toggleCartDrawer(anchor, false)}
    >
      <div onClick={() => false} style={{ color: "white" }}>
        sds
      </div>
      <IconButton
        className={classes.closeCartButton}
        size="small"
        onClick={toggleCartDrawer(anchor, false)}
      >
        <CloseIcon />
      </IconButton>
      <Hidden>
        <div ref={closeCartRef} onClick={toggleCartDrawer(anchor, false)} />
      </Hidden>
      <Cart
        history={props.history}
        handleClose={() => toggleCartDrawer(anchor, false)}
        closeCartRef={closeCartRef}
      />
    </div>
  );

  const cartComponent = (
    <CardActionArea className={classes.logoAction}>
      <Drawer
        anchor="right"
        open={cartDrawer["right"]}
        onClose={toggleCartDrawer("right", false)}
        className={classes.cartMob}
      >
        {listCart("right")}
      </Drawer>
      <div style={{ position: "relative" }}>
        <img
          alt="cart"
          src="/cart.png"
          onClick={toggleCartDrawer("right", true)}
        />
        <div
          ref={cartRef}
          className={classes.cartCount}
          onClick={toggleCartDrawer("right", true)}
        >
          <Typography color="textSecondary">
            {Object.keys(cart).length}
          </Typography>
        </div>
      </div>
    </CardActionArea>
  );
  const onCategoryChange = (val, heading, code) => {
    handleCloseSweetMenu();
    props.history.push({
      pathname: `/category/${val}`,
      state: {
        heading,
        route: val,
        code,
      },
    });
  };

  const onOccasionChange = (val) => {
    handleCloseOccasionMenu();
    if (Number(val)) props.history.push(`/occasions/${val}`);
  };

  const gotoHome = () => {
    props.history.push("/");
  };
  const logoutHandler = () => {
    localStorage.setItem("token", "");
    localStorage.setItem("profile", "");
    localStorage.setItem("cart", JSON.stringify({}));
    handleCloseMenu();
    dispatch({
      type: LOGOUT_ACTION,
    });
    props.history.replace("/");
  };
  if (
    props.history.location.pathname === "/login" ||
    props.history.location.pathname === "/signup" ||
    props.history.location.pathname === "/forgotpassword"
  ) {
    return <div />;
  }
  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar className={classes.toolBarWeb}>
        <CardActionArea className={classes.logoAction} onClick={gotoHome}>
          <img alt="milky" src="/logo.svg" className={classes.logo} />
        </CardActionArea>
        <div className={classes.grow} />
        <div className="dropdown">
          <button className={clsx("dropbtn", classes.btnPopover)}>
            <Typography className={classes.link}>Sweets</Typography>
            <ExpandMoreIcon style={{ color: "#615A5A" }} />
          </button>
          <div className="dropdown-content">
            <MenuItem
              className={classes.menuItem}
              value="subCategories?sub=ghee"
              onClick={onCategoryChange.bind(
                this,
                "subCategories?sub=ghee",
                "All Ghee Sweets",
                "ghee"
              )}
            >
              Ghee sweets
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              value="subCategories?sub=khoya"
              onClick={onCategoryChange.bind(
                this,
                "subCategories?sub=khoya",
                "All Khoya Sweets",
                "khoya"
              )}
            >
              Khoya sweets
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              value="subCategories?sub=bengali"
              onClick={onCategoryChange.bind(
                this,
                "subCategories?sub=bengali",
                "All Chena Sweets",
                "bengali"
              )}
            >
              Chena sweets
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              value="subCategories?sub=dry"
              onClick={onCategoryChange.bind(
                this,
                "subCategories?sub=dry",
                "All Dry Fruits Sweets",
                "dry"
              )}
            >
              Dry Fruits sweets
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              value="subCategories?sub=other"
              onClick={onCategoryChange.bind(
                this,
                "subCategories?sub=other",
                "All Other Sweets",
                "other"
              )}
            >
              Other Sweets
            </MenuItem>
          </div>
        </div>
        <Link
          to={{
            pathname: "/category/subCategories?sub=savories",
            state: {
              heading: "ALl Savouries",
              code: "savories",
            },
          }}
          className={classes.link}
        >
          Savouries
        </Link>
        <Link
          to={{
            pathname: "/category/subCategories?sub=dry-fruits",
            state: {
              heading: "All Dry Fruits",
              code: "dry-fruits",
            },
          }}
          className={classes.link}
        >
          Dry Fruits
        </Link>
        <Link
          to={{
            pathname: "/category/subCategories?sub=dessert",
            state: {
              heading: "All Dessert",
              code: "dessert",
            },
          }}
          className={classes.link}
        >
          Desserts
        </Link>

        <div className="dropdownOccasions">
          <button className={clsx("dropbtnOccasions", classes.btnPopover)}>
            <Typography className={classes.link}>Occasions</Typography>
            <ExpandMoreIcon style={{ color: "#615A5A" }} />
          </button>
          <div className="dropdown-content-occasions">
            {occasions.map((data, idx) => (
              <MenuItem
                key={idx}
                className={classes.menuItem}
                value={data.id}
                onClick={onOccasionChange.bind(this, data.id)}
              >
                {data.name}
              </MenuItem>
            ))}
          </div>
        </div>
        <Button
          className={classes.bulkBtn}
          onClick={onOccasionChange.bind(this, "1")}
        >
          Bulk Order
        </Button>
        {!token && (
          <Button
            className={classes.bulkBtn}
            onClick={handleClickPop}
            aria-describedby="popover"
          >
            Login/Signup
          </Button>
        )}
        <Popover
          id="popover"
          anchorEl={anchorElPop}
          open={Boolean(anchorElPop)}
          onClose={handleClosePop}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className={classes.popover}
        >
          <LoginCallout history={props.history} handleClose={handleClosePop} />
        </Popover>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          className={classes.profileMenu}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              props.history.push("/orders");
            }}
          >
            My Orders
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              props.history.push("/profile");
            }}
          >
            Profile + Settings
          </MenuItem>
          <Divider />
          <Divider />
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onOccasionChange("1");
            }}
          >
            Corporate Enquiry
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              if (props.history.location.pathname !== "/") {
                props.history.push({
                  pathname: "/",
                  state: {
                    focusonQueryForm: true,
                  },
                });
              } else {
                props.focusonQueryForm();
              }
            }}
          >
            Help and Support
          </MenuItem>
          <Divider />
          <MenuItem onClick={logoutHandler}>Logout</MenuItem>
        </Menu>
        {cartComponent}
        {token && (
          <CardActionArea
            className={classes.logoAction}
            onClick={handleClickMenu}
          >
            {_.get(profile, "fname") ? (
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className={classes.nameF}>
                  <span>{_.get(profile, "fname").split("")[0]}</span>
                </div>
                <span className={classes.nameFl}>
                  {_.get(profile, "fname")}
                </span>
              </div>
            ) : (
              <img alt="profile" src="/profile.png" />
            )}
          </CardActionArea>
        )}
      </Toolbar>
      <Toolbar className={classes.toolBarMobile}>
        <React.Fragment key={"left"}>
          <img
            alt="milkytary"
            src="/leftmenu.svg"
            onClick={toggleDrawer("left", true)}
          />
          <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
        </React.Fragment>
        <CardActionArea className={classes.logoAction} onClick={gotoHome}>
          <img alt="milky" src="/logo.svg" className={classes.logo} />
        </CardActionArea>
        {cartComponent}
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Navbar);
